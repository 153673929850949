<template>
  <div class="pcb-container">
    <PcbQoute parentSite="pcb" />
  </div>
</template>

<script>
import PcbQoute from '@@/views/pcb/index'
export default {
  name: 'ordersPcb',
  components: {
    PcbQoute
  }
}
</script>

<style lang="scss" scoped>
</style>
