<!-- 板材品牌 -->
<template>
  <BaseFormItem
    class="board_brand"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <BaseTipPopover
        v-for="item in options" 
        :key="item.value"
        width="380px"
        :content="item.tips" 
        :disabled="!item.tips"
      >
        <el-radio-button
          slot="reference"
          :label="item.value"
          :disabled="item.disabled"
        >{{ item.label }}</el-radio-button>
      </BaseTipPopover>
    </BaseRadioGroup>

    <BaseLink
      v-if="downloadPdfUrl"
      :href="downloadPdfUrl"
      target="_blank"
    >下载板材技术资料</BaseLink>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // HDI(盲埋孔)
    blind: {
      type: Number,
      default: 0
    },
    // TG值
    board_tg: {
      type: String,
      defalut: ''
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value,
      otherVal: '', // 当前选项之外的值
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('board_brand')
    },
    options() {
      const { blayer, blind, otherVal } = this
      // 无要求提示
      const tips = blayer < 4 
        ? '对于没有板材要求的订单，我司会根据仓库库存随机选用板材，\n可能会使用到建滔KB、国纪或者其他型号同等品质的板材。'
        : '多层板对于没有板材要求的订单，我司会根据仓库库存随机选用板材，可能会使用到建滔KB、生益或者不低于生益品质的板材。'
      
      const options = [
        {
          flag: blayer < 10,
          option: { value: '无要求', label: '无要求', tips }
        },
        {
          flag: blayer == 1 || blayer == 2 || (blayer == 4 && blind == 0),
          option: { value: '建滔', label: '建滔KB' }
        },
        {
          flag: blayer > 4 || (blayer == 4 && blind == 1),
          option: { value: '生益', label: '生益' }
        }
      ].filter(i => i.flag).map(i => i.option)
      // 当值不在可选项中时，添加一个当前值的可选项，且在值改变时移除添加的可选项
      const otherOption = otherVal ? [{ value: otherVal, label: otherVal }] : []

      return [...options, ...otherOption]
    },
    // 下载板材技术资料链接
    downloadPdfUrl() {
      const { val, blayer, board_tg } = this
      if (val === '建滔') {
        if (blayer < 4) return 'https://file1.elecfans.com/web2/M00/8C/96/wKgaomSuZ9CADurzAAfh9ANUOW8971.pdf'
        return {
          TG130: 'https://file1.elecfans.com/web2/M00/8C/96/wKgaomSuZ9CADurzAAfh9ANUOW8971.pdf', 
          TG150: 'https://file.elecfans.com/web2/M00/A2/2C/pYYBAGRIgfGANmjxAAcHiIEl3fk856.pdf' 
        }[board_tg]
      }
      if (val === '生益') {
        return { 
          TG130: 'https://file.elecfans.com/web2/M00/A1/AC/poYBAGRIgguASQrtAAIFpIo7AXk730.pdf', 
          TG150: 'https://file.elecfans.com/web2/M00/A2/2C/pYYBAGRIgkSAFRPYAAXHs20cFK4807.pdf', 
          TG170: 'https://file.elecfans.com/web2/M00/A1/AC/poYBAGRIgmyAe46KAAXMWo0mH8Q186.pdf' 
        }[board_tg]
      }

      return ''
    }
  },
  watch: {
    value(newVal) {
      // 当前值不是选项外的值时，清空选项外的值
      if (newVal != this.otherVal) {
        this.otherVal = ''
      }
      this.val = newVal
    },
    // 板子层数改变时 设置默认值
    blayer(blayer) {
      this.handleResetVal()
    },
    // HDI(盲埋孔)改变时 设置默认值
    blind(val) {
      this.handleResetVal()
    }
  },
  created() {
    const { val, options } = this
    // 当前值不在选项中时，添加一个当前值的选项
    if (val && options.every(i => i.value !== val)) {
      this.otherVal = val
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    },
    handleResetVal() {
      const { val, options } = this
      // 当板材品牌没值或者值不在选项中时，设置默认值为第一个选项的值
      if (!val || options.every(i => i.value != val)) {
        this.changeVal(options[0].value)
      }
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.board_brand {
  .base-link {
    margin-left: 10px;
  }
}
</style>