<!-- 报告形式 -->
<template>
  <BaseFormItem
    class="report_type"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <span v-if="val == 1" slot="other" class="report_type__tips">
      发货后，可在【我的订单】-【订单材料】页下载相关电子报告
    </span>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('report_type')
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
.report_type {
  .report_type__tips {
    font-size: 12px;
    color: $--color-text-placeholder;
  }
}
</style>