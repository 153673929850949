<!-- 品质赔付方式 -->
<template>
  <BaseFormItem
    class="insurance_type"
    isShowRequiredIcon
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val.insurance_type">
      <BaseTipPopover 
        v-for="item in fields.options"
        :key="item.value" 
        :content="item.tips" 
        :disabled="!item.tips"
      >
        <el-radio-button
          slot="reference"
          :label="item.value"
          :disabled="item.disabled"
          @click.native.prevent="handleRadioClick(item.value)"
        >
          {{ item.label }}
          <template v-if="item.value === 1 && !!insurance">
            <el-button type="text" class="insurance_type__changeBtn" @click.stop="openDialog">
              &nbsp;&nbsp;修改
            </el-button>
          </template>
        </el-radio-button>
      </BaseTipPopover>
    </BaseRadioGroup>
    <BaseLink slot="other" href="https://www.hqpcb.com/pcbnews/1695.html" target="_blank">品质赔付标准说明</BaseLink>
    <!-- 品质赔付 PCBA单片成本价弹框 TODO -->
    <InsuranceTypeDialog ref="qualityInsuranceDialog" :pcbParams="pcbParams" @confirm="handlConfirm" />
  </BaseFormItem>
</template>

<script>
import InsuranceTypeDialog from '../components/insuranceTypeDialog'

import { getFR4Fields } from '@@/utils/getFields'

export default {
  props: {
    // 品质赔付方式
    insurance_type: {
      type: [Number, String],
      default: ''
    },
    // 品质赔付 PCBA单片成本价
    insurance: {
      type: [String, Number],
      default: ''
    },
    // // 出货形式
    // units: {
    //   type: Number,
    //   default: 1
    // },
    // // 单片数量
    // bcount: {
    //   type: [String, Number],
    //   default: ''
    // },
    // // 拼版规则 纵片数量
    // layouty: {
    //   type: [String, Number],
    //   default: ''
    // },
    // // 拼版规则 横片数量
    // layoutx: {
    //   type: [String, Number],
    //   default: ''
    // },
    pcbParams: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    InsuranceTypeDialog
  },
  data() {
    return {
      val: {
        insurance_type: this.insurance_type,
        insurance: this.insurance
      }
    }
  },
  computed: {
    fields() {
      return getFR4Fields('insurance_type')
    }
  },
  watch: {
    insurance_type(newVal) {
      this.val.insurance_type = newVal
    },
    insurance(newVal) {
      this.val.insurance = newVal
    },
    // pcbParams: {
    //   deep: true,
    //   handler: function (pcbParams) {
    //     this._pcbParams = pcbParams
    //   }
    // }
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
      this.handleEmitChange()
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
    handleRadioClick(insurance_type) {
      // 当选择 自动扣款并确认，且没设置扣款差额时显示弹框，否则直接选择
      if (insurance_type === 1 && !this.val.insurance) {
        this.openDialog()
      } else {
        this.val.insurance_type = insurance_type
        this.handleEmit('insurance_type')
      }
    },
    openDialog() {
      const { units, bcount, layouty, layoutx } = this.pcbParams
      if (!bcount) {
        this.$message.warning('您还未选择板子数量，需补充后才能计算')
        return
      }
      // 连片(华秋代拼)
      if (units == 2 && !layouty && !layoutx) {
        this.$message.warning('您还未输入拼版规则，需补充后才能计算')
        return
      }
      this.$refs.qualityInsuranceDialog.open(this.val.insurance)
    },
    handlConfirm(insurance) {
      this.val = { insurance_type: 1, insurance }
      this.handleEmit('insurance_type')
      this.handleEmit('insurance')
    }
  }
}
</script>

<style lang="scss" scoped>
.insurance_type {
  .insurance_type__changeBtn {
    margin-left: 2px;
    padding: 0;
    border: 0;
    border-left: $--border-base;
    color: $--form-color-base;
  }
}
</style>