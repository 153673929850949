<!-- 确认生产稿 -->
<template>
  <BaseFormItem
    isShowRequiredIcon
    :label="fields.label"
    :labelTips="fields.labelTips"
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
    valueConfig="review_file"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <BaseTipPopover 
        v-for="item in options"
        :key="item.value" 
        :content="item.tips" 
        :disabled="!item.tips"
      >
        <el-radio-button
          slot="reference"
          :label="item.value"
          :disabled="item.disabled"
        >{{ item.label }}</el-radio-button>
      </BaseTipPopover>
    </BaseRadioGroup>
    <ReviewFileDialog slot="other" />
    <div v-if="isShowSmtTips" slot="extend" class="review_file__tips">
      因您需要SMT，我们会帮您添加或修改工艺边或MARK点，为尽量规避因电路板出问题从而导致PCBA元器件的惨重损失，<span>建议您选择需要确认生产稿。</span>
    </div>
  </BaseFormItem>
</template>

<script>
import ReviewFileDialog from '../components/reviewFileDialog'

import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 客编
    user_stamp: {
      type: Number,
      default: 0
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    },
    isShowSmtTips: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ReviewFileDialog
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('review_file')
    },
    options() {
      const { fields, user_stamp } = this
      const options = fields.options
      
      let disabledOptions = []
      //  当客编 = 指定位置加客编 时, 禁用 无需 选项
      if (user_stamp === 2) {
        disabledOptions.push(0)
      }
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    },
    // 是否显示未填写样式
    fillTips() {
      const val = this.val
      return !val && val !== 0 ? '请选择' : ''
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    user_stamp(newVal) {
      // 当客编 = 指定位置加客编 且选中 无需 时，清空选中状态
      if (newVal === 2 && this.val === 0) {
        this.changeVal('')
      }
    }, 
    blayer(newVal) {
      const val =this.val
      // 当板子层数 > 6时，默认选中 需要(不允许自动确认)
      if (newVal > 6 && !val && val !== 0) {
        this.changeVal(2)
      }
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
.review_file__tips {
  margin-top: 10px;
  padding-right: 10px;
  width: 100%;
  line-height: 1.5;
  font-size: 12px;
  color: $--color-text-placeholder;
  >span {
    color: $--color-warning;
  }
}
</style>