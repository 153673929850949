<template>
  <div class="pressing-baseInfo">
    <p class="pressing-baseInfo__title">板子基本信息</p>
    <ul class="pressing-baseInfo__order">
      <li v-for="(val, key) in order">
        <span>{{ orderItems[key].label }}</span>
        <span>{{ orderItems[key].formatter ? orderItems[key].formatter(val) : val }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      orderItems: {
        blayer: { label: '层数' }, // 层数
        bheight: { label: '板厚' }, // 板厚
        copper: { label: '外层铜厚', formatter: val => `${val}OZ` }, // 外层铜厚
        insidecopper: { label: '内层铜厚', formatter: val => `${val}OZ` }, // 内层铜厚
        hdi: { label: '盲埋孔', formatter: val => ({ 0: '无', 1: '有' }[val]) }, // HDI(盲埋孔) 1:有，0:无
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.pressing-baseInfo {
  $border: 1px solid #dcdfe6;
  .pressing-baseInfo__title {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .pressing-baseInfo__order {
    display: flex;
    border: $border;
    border-right: 0;
    >li {
      display: flex;
      width: 25%;
      line-height: 32px;
      >span {
        display: block;
        border-right: $border;
        &:first-child {
          padding-left: 10px;
          width: 40%;
          font-size: 12px;
          color: $--color-text-secondary;
          background-color: #F5F7FA;
        }
        &:last-child {
          flex: 1;
          padding-left: 15px;
        }
      }
    }
  }
}
</style>