<!-- 表面处理 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
    :labelTipsWidth="fields.labelTipsWidth"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>

    <div slot="labelTipsContent" class="cover__labelTipsContent">
      <ul>
        <li v-for="item in labelTipsContent" :key="item.title">
          <p class="cover__labelTipsContent__title">{{ item.title }}</p>
          <p class="cover__labelTipsContent__img"><img :src="item.img" width="110" /></p>
          <p class="cover__labelTipsContent__text">{{ item.text }}</p>
        </li>
      </ul>
    </div>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: String,
      default: '有铅喷锡'
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 半孔
    bankong: {
      type: Number,
      default: 0
    },
    // 包边
    baobian: {
      type: Number,
      default: 0
    },
    // TODO
    // 禁用OSP 
    disosp: {
      type: Boolean,
      default: false
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value,
      labelTipsContent: [
        { title: '沉金', img: require('@@/assets/img/pcb/label/spray_1.png'), text: '沉金具有环保、焊盘平整，易焊接等优势，价格相对高一些，适用于难度高的板子。' },
        { title: '喷锡', img: require('@@/assets/img/pcb/label/spray_2.png'), text: '喷锡容易焊接，但是平整度较差，适用于普通板子。' },
        { title: 'OSP', img: require('@@/assets/img/pcb/label/spray_3.png'), text: 'OSP符合环保要求,尺寸最小需8*5CM。' },
      ],
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('spray')
    },
    options() {
      const { fields, bankong, baobian } = this
      const options = fields.options
      let disabledOptions = bankong > 0 || baobian > 0 ? ['OSP'] : []
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    blayer(blayer) {
      // 当板子层数大于8层，设当前值为'沉金'
      if (blayer > 8) {
        if(this.val != '沉金') {
          this.changeVal('沉金')
        }
      } else if (this.val != '有铅喷锡') {
        this.changeVal('有铅喷锡')
      }
    },
    bankong() {
      this.bankongOrBaobianChange()
    },
    baobian() {
      this.bankongOrBaobianChange()
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
      this.isShowError(false)
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    },
    // 半孔/包边改变时
    bankongOrBaobianChange() {
      const { val, options, bankong, baobian } = this
      const disabledOptions = options.filter(item => item.disabled).map(item => item.value)
      // 有半孔/包边，且当前所选项(OSP)被禁用
      if ((bankong > 0 || baobian > 0) && disabledOptions.includes(val)) {
        // 设当前值为可选项第一个选项的值
        this.val = this.options.filter(item => !item.disabled)[0].value
        this.handleEmit()
        // 弹出提示
        this.$alert(`有半孔/包边的板子，表面处理不能做${val}`, '温馨提示', {
          confirmButtonText: '好的',
          lockScroll: false,
          center: true,
          closeOnPressEscape: true
        })
      }
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.cover__labelTipsContent {
  width: 459px;
  font-size: 12px;
  >ul {
    display: flex;
    align-items: stretch;
    border-right: 1px solid #EBEEF5;
    border-bottom: 1px solid #EBEEF5;
    >li {
      flex: 1;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 126px;
      &:last-child {
        width: 161px;
      }
      >p {
        padding: 10px;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
      }
      .cover__labelTipsContent__title {
        padding: 6px 10px;
        text-align: center;
        background-color: #F5F7FA;
      }
      .cover__labelTipsContent__img {
        text-align: center;
      }
      .cover__labelTipsContent__text {
        flex: 1;
      }
    }
  }
}
</style>