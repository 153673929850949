<!-- 隔白纸 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    // 板子尺寸 长
    length: {
      type: [String, Number],
      default: ''
    },
    // 板子尺寸 宽
    width: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('paper')
    },
    options() {
      const { val, fields, length, width } = this
      const options = fields.options
      // 板长、板宽任意一边尺寸小于5cm 白纸就不能选择“需要”选项
      let disabledOptions = [length, width].some(i => i < 5) ? [2] : []
      // 如果当前项被禁用，选中第一个可选的选项
      if (disabledOptions.includes(val)) {
        this.changeVal(options.find(item => !disabledOptions.includes(item.value)).value)
      }
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    changeVal(val) {
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
</style>