<!-- 阻焊覆盖 -->
<template>
  <BaseFormItem
    class="cover"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :labelTipsWidth="fields.labelTipsWidth"
    valueConfig="cover"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <BaseTipPopover 
      width="536px" 
      title="阻焊覆盖"
      placement="bottom"
    >
      <div slot="reference" class="cover__tips">
        <p><span>* 如果是gerber文件，此选项中【过孔盖油】、【过孔开窗】选项无效，我司一律按文件中过孔属性加工</span></p>
        <p><span>* 过孔盖油检验标准是过孔在过锡炉的时候不沾锡，过孔盖油会出现孔口发红现象，属于工艺正常现象</span></p>
        <p><span>* 喷锡板存在过孔藏锡珠风险，如不接受过孔藏锡珠，请按过孔塞油墨下单</span></p>
      </div>
      <div class="cover__labelTipsContent">
        <ul>
          <li v-for="item in coverTipsContent" :key="item.title">
            <p class="cover__labelTipsContent__title">{{ item.title }}</p>
            <p class="cover__labelTipsContent__img"><img :src="item.img" /></p>
            <p class="cover__labelTipsContent__text">{{ item.text }}</p>
          </li>
        </ul>
      </div>
    </BaseTipPopover>
    
    
    <div slot="labelTipsContent" class="cover__labelTipsContent">
      <ul>
        <li v-for="item in labelTipsContent" :key="item.title">
          <p class="cover__labelTipsContent__title">{{ item.title }}</p>
          <p class="cover__labelTipsContent__img"><img :src="item.img" width="104" /></p>
          <p class="cover__labelTipsContent__text">{{ item.text }}</p>
        </li>
      </ul>
      <p class="cover__labelTipsContent__otherTips">
        因gerber文件无法分清过孔和插件孔，若您的文件是gerber格式，我司一律按文件加工.
      </p>
    </div>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: String,
      default: '过孔盖油'
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 外层铜厚
    copper: {
      type: Number,
      default: 1
    },
    // 内层铜厚
    insidecopper: {
      type: Number,
      default: 1
    },
    // 表面处理
    spray: {
      type: String,
      defalut: ''
    },
    // 盘中孔
    via_in_pad: {
      type: String,
      defalut: ''
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value,
      labelTipsContent: [
        { title: '过孔盖油', img: require('@@/assets/img/pcb/label/cover_1.png'), text: '生产时使用连塞带印工艺，一次性将过孔和孔环覆盖阻焊油，过孔在过锡炉的时候不沾锡，少部分孔可能会透光或孔口发红现象，属于工艺正常现象。' },
        { title: '过孔开窗', img: require('@@/assets/img/pcb/label/cover_2.png'), text: '过孔和孔环均不覆盖阻焊油。' },
        { title: '过孔塞油墨', img: require('@@/assets/img/pcb/label/cover_3.png'), text: '生产时先用铝片将阻焊油墨塞进过孔里面，再整板印阻焊油，所有过孔都不会透光。' },
        { title: '过孔塞树脂+电镀填平', img: require('@@/assets/img/pcb/label/cover_4.png'), text: '生产时在过孔内塞树脂，孔口凸起树脂磨平后，再将孔口盖铜填平，可靠性更好，所有过孔都不会透光。价格较贵，交期会延长几天，请慎重选择。' },
      ],
      coverTipsContent: [
        { title: 'gerber文件', img: require('@@/assets/img/pcb/cover_tips_1.png'), text: '如果是gerber文件，此选项中【过孔盖油】、【过孔开窗】选项无效，我司一律按文件中过孔属性加工' },
        { title: '过孔盖油', img: require('@@/assets/img/pcb/cover_tips_2.png'), text: '过孔盖油出现孔口发红和过孔藏锡珠属于工艺正常现象如不接受，请按【过孔塞油墨】下单' },
        { title: '过孔塞油墨', img: require('@@/assets/img/pcb/cover_tips_3.png'), text: '【过孔塞油墨】对于“单面开窗的过孔”设计无法保证塞孔效果(出现此过孔开窗面冒油及过孔透光，漏锡)，如不接受，请选[过孔塞树脂+电镀填平]' },
        { title: '过孔塞树脂+电镀填平', img: require('@@/assets/img/pcb/cover_tips_4.png'), text: '生产时在过孔内塞树脂，孔口凸起树脂磨平后，再将孔口盖铜填平，可靠性更好，所有过孔都不会透光' },
      ]
    }
  },
  computed: {
    fields() {
      return getFR4Fields('cover')
    },
    options() {
      const { val, fields, copper, insidecopper, via_in_pad, blayer } = this
      const options = fields.options
      let disabledOptions = []
      // 盘中孔 == 有, 禁用除 过孔塞树脂+电镀填平 之外的选项
      if (via_in_pad == '有') {
        disabledOptions = options.filter(item => item.value != '过孔塞树脂+电镀填平').map(i => i.value)
      }
      // 外层铜厚 >= 3 || 内层铜厚 >= 8
      // 单双面板
      if (copper >= 3 || insidecopper >= 3 || [1, 2].includes(blayer)) {
        disabledOptions = ['过孔塞树脂+电镀填平']
      }

      // 如果当前项被禁用，选中第一个可选的选项
      if (disabledOptions.includes(val)) {
        this.changeVal(options.find(item => !disabledOptions.includes(item.value)).value)
      }
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    spray(spray) {
      //  当 表面处理 === OSP 且 阻焊覆盖 === '过孔盖油' || '过孔开窗' 时，改为选中 '过孔塞油墨'
      if (spray === 'OSP' && ['过孔盖油', '过孔开窗'].includes(this.val)) {
        this.changeVal('过孔塞油墨')
      }
    },
    via_in_pad(via_in_pad) {
      // 当 盘中孔 === 有 且 阻焊覆盖 !== '过孔塞树脂+电镀填平' 时，选中 '过孔塞树脂+电镀填平'
      if (via_in_pad === '有' && this.val !== '过孔塞树脂+电镀填平') {
        this.changeVal('过孔塞树脂+电镀填平')
      }
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
.cover {
  .cover__tips {
    margin-top: 4px;
    line-height: 1.5;
    font-size: $--font-size-small;
    color: $--color-text-placeholder;
    span {
      color: $--color-warning;
    }
  }
}
.cover__labelTipsContent {
  width: 536px;
  font-size: 12px;
  >ul {
    display: flex;
    align-items: stretch;
    border-right: 1px solid #EBEEF5;
    border-bottom: 1px solid #EBEEF5;
    >li {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 126px;
      &:last-child {
        width: 161px;
      }
      >p {
        padding: 10px;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
      }
      .cover__labelTipsContent__title {
        padding: 6px 10px;
        text-align: center;
        background-color: #F5F7FA;
      }
      .cover__labelTipsContent__img {
        text-align: center;
      }
      .cover__labelTipsContent__text {
        flex: 1;
      }
    }
  }
  .cover__labelTipsContent__otherTips {
    padding: 6px 10px;
    font-size: 12px;
    border: 1px solid #EBEEF5;
    border-top: 0;
    background-color: #F5F7FA;
  }
}
</style>