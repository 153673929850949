<!-- 手指斜边 -->
<template>
  <BaseFormItem
    class="beveledge"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
    valueConfig="beveledge"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <BaseTipPopover 
        v-for="item in options"
        :key="item.value" 
        :content="item.tips" 
        :disabled="!item.tips"
      >
        <el-radio-button
          slot="reference"
          :label="item.value"
          :disabled="item.disabled"
        >{{ item.label }}</el-radio-button>
      </BaseTipPopover>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    pcbSize: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('beveledge')
    },
    options() {
      const { fields, pcbSize: { length, width } } = this
      const options = fields.options
      let disabledOptions = []
      // 手指斜边工艺，加工尺寸长和宽需满足4-28CM区间
      if (length && width && ((length < 4 || length > 28) || (width < 4 || width > 28))) {
        disabledOptions.push(1)
      }
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
.beveledge__tips__link {
  >a {
    color: $--link-color;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>