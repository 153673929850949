<!-- 阻抗叠层计算弹框 -->
<template>
  <el-dialog
    width="1200px"
    top="100px"
    title="阻抗匹配叠层计算"
    custom-class="pressing-dialog"
    append-to-body
    :visible="isShow"
    :lock-scroll="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose"
  >
    <div v-loading="loading" class="pressing-dialog__content">
      <!-- 板子基本信息 -->
      <BaseInfo :order="order" />
      <!-- 参数设置 -->
      <Params 
        v-if="!isView" 
        ref="ParamsRef"
        :order="order" 
        @compute="handleCompute" 
        @reset="handleParamsReset" 
      />
      <!-- 叠层结构列表 -->
      <div class="pressing-list">
        <p class="pressing-list__title">华秋叠层结构</p>
        <div
          v-for="(item, index) in list" 
          :key="item.pressing_with_pp"
          class="pressing-list__item"
          :class="{ 'is-checked': !isView && currentPressingWithPP === item.pressing_with_pp }"
        >
          <p>
            <el-radio 
              v-if="!isView"
              v-model="currentPressingWithPP"
              :label="item.pressing_with_pp"
              :disabled="hasRedColorItem(item.impedance).length > 0"
            ></el-radio>
            {{ `结构${index + 1}：华秋${item.pressing_with_pp}` }}
            <span v-if="hasRedColorItem(item.impedance).length > 0">
              此结构计算结果不符合制作要求：{{ hasRedColorItem(item.impedance).join('、') }}
            </span>
          </p>
          <div>
            <!-- 阻抗表 -->
            <Impedance :list="item.impedance" />
            <!-- 压合结构 -->
            <Stack :list="item.stack" />
          </div>
        </div>
      </div>
    </div>

    <div slot="footer" class="pressing-dialog__footer">
      <el-button @click="handleClose">{{ !isView ? '取 消' : '关 闭' }}</el-button>
      <el-button v-if="!isView" type="primary" @click="handleConfirm">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import BaseInfo from './baseInfo'
import Params from './params'
import Impedance from './impedance'
import Stack from './stack'

import { sensorsTrack } from '@@/utils/sensors'
import { getPressingList } from '@@/api/pcb'

export default {
  props: {
    // 已选的推荐结构编号
    value: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      defalut: () => ({})
    },
  },
  components: {
    BaseInfo,
    Params,
    Impedance,
    Stack
  },
  data() {
    return {
      isShow: false,
      isView: false,
      loading: false,
      currentPressingWithPP: this.value, // 当前选中的推荐结构编号
      currentPressingComputerId: '', // 当前推荐结构的计算id
      list: []
    }
  },
  computed: {
    order() {
      const defalutOrder = {
        blayer: 4, // 层数
        bheight: 1.6, // 板厚
        copper: 1, // 外层铜厚
        insidecopper: 0.5, // 内层铜厚
        hdi: 0, // HDI(盲埋孔) 1:有，0:无
      }
      return Object.assign(defalutOrder, this.params)
    }
  },
  methods: {
    open(viewPressingData) {
      if (viewPressingData) {
        this.list = [viewPressingData]
        this.isView = true
      } else {
        this.list = []
        this.isView = false
      }
      this.isShow = true
    },
    handleClose() {
      this.isShow = false
      this.$refs.ParamsRef && this.$refs.ParamsRef.handleReset()
    },
    // 判断是否有标红的项
    hasRedColorItem(impedance) {
      const limits = { line_width: 3, line_space: 3.8, copper_space: 4, result_color: 1 }
      const isRed = item => Object.entries(limits).reduce((keys, [key, limit]) => {
        const value = item[key]
        if ((key === 'result_color' && value === 1) || (!value || (value !== '/' && Number(value) < limit))) {
          keys.push(key)
        }
        return keys
      }, [])
      const redKeys = impedance.reduce((keys, item) => [...keys, ...isRed(item)], [])
      return [...new Set(redKeys)].map(key => ({
        line_width: '线宽小于3mil', 
        line_space: '线距小于3.8mil', 
        copper_space: '到铜距离小于4mil',
        result_color: '计算结果与阻抗值相差±2ohm'
      }[key]))
    },
    // 计算
    handleCompute(params) {
      // 重置已有选项
      this.list = []
      this.loading = true

      // 神策数据埋点
    sensorsTrack('Pcb_impedance_calculate_click', { entrance: '阻抗匹配叠层工具-计算' })

      const order = this.order
      getPressingList({ order, pressing: params })
        .then(res => {
          if (!res) return

          const { status, data, msg } = res
          if (status == 1) {
            const { pressing_result, pressing_computer_id } = data
            // 推荐结构列表
            this.list = pressing_result || []
            this.currentPressingComputerId = pressing_computer_id
            // this.handleEmitCompute({ pressing_computer_id, list: pressing_result, params })
          } else {
            this.$message.error(msg)
          }
        })
        .catch(err => {
          this.$message.error(err.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 参数重置
    handleParamsReset() {
      this.currentPressingWithPP = ''
      this.list = []
    },
    // 确认
    async handleConfirm() {
      if (!this.currentPressingWithPP) {
        this.$message.error('请选择叠层结构')
        return
      }
      const { currentPressingWithPP, currentPressingComputerId, list } = this
      const pressingData = list.find(item => item.pressing_with_pp === currentPressingWithPP)
      // 校验 原始线宽 和 线宽 差距
      const flag = await this.validateLineWidth(pressingData)
      if (!flag) return

      this.$emit('confirm', { pressing_computer_id: currentPressingComputerId, pressing_computer_pp: currentPressingWithPP, pressing_data: pressingData })
      this.handleClose()
    },
    // 校验线宽
    async validateLineWidth(item) {
      // 判断 原始线宽 和 线宽 相差是否大于等于 1.5
      const flag = (item.impedance || []).some(({original_line_width, line_width}) => Math.abs(original_line_width - line_width) >= 1.5)
      if (flag) {
        return await this.$confirm('原始线宽和线宽差距在1.5以上请确认是否接受？', '温馨提示', {
          center: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        .then(() => true)
        .catch(() => false)
      }
      return true
    }
  },
}
</script>

<style lang="scss">
.pressing-dialog {
  $border: 1px solid #dcdfe6;
  .el-dialog__body {
    padding: 0 20px;
    height: 600px;
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }
  .el-dialog__footer {
    position: relative;
    z-index: 10;
    text-align: center;
    box-shadow: 0 -4px 6px 0 rgba(0,0,0,.1);
  }

  .pressing-dialog__footer {
    .el-button {
      padding: 12px 40px;
    }
  }

  .el-button {
    &.el-button--default:not(.is-disabled):hover {
      color: $--form-color-base;
      border-color: $--form-color-base;
      background-color: #fff;
    }
    &.el-button--primary {
      background-color: $--color-primary;
      border-color: $--color-primary;
      &:hover {
        background-color: $--color-primary;
        border-color: $--color-primary;
      }
      &.is-disabled {
        color: #666666;
        background-color: #DCDFE6;
        border-color: #DCDFE6;
      }
    }
  }

  .el-radio {
    margin-right: 10px;
    .el-radio__input {
      .el-radio__inner:hover {
        border-color: $--color-primary;
      }
      &.is-checked .el-radio__inner {
        border-color: $--color-primary;
        background-color: transparent;

        &::after {
          width: 8px;
          height: 8px;
          background-color: $--color-primary;
        }
      }
    }
    .el-radio__label {
      display: none;
    }
  }

  // 修改 el-table 样式
  .pressing-table {
    font-size: 12px;
    border: 0;
    border-top: $border;
    border-left: $border;
    thead th.is-leaf {
      background-color: #F5F7FA;
    }
    th,
    td {
      padding: 4px 0;
      border: 0;
      border-right: $border;
      border-bottom: $border;
    }
  }
}
</style>

<style lang="scss" scoped>
.pressing-list {
  margin-top: 10px;
  .pressing-list__title {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .pressing-list__item {
    margin-bottom: 20px;
    border-radius: 2px;
    border: 1px solid #DCDFE6;
    &.is-checked {
      border-color: $--color-primary;
    }
    >p {
      padding-left: 20px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 700;
      background-color: #E8EFFF;
      >span {
        margin-left: 20px;
        font-weight: 400;
        color: $--color-warning;
      }
    }
    >div {
      padding: 10px 20px 20px;
    }
  }
}
</style>