<!-- 推荐拼版列表 -->
<template>
  <div class="recommend-list">
    <el-table
      border
      size="mini"
      style="width: 100%"
      height="112"
      :data="list"
    >
      <el-table-column prop="board_method" label="推荐拼版方式" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="pnl_usage_rate" label="利用率" width="60" align="center">
      </el-table-column>
      <el-table-column prop="date" label="拼版后尺寸" width="105" align="center">
        <template slot-scope="{ row }">
          {{ row.blength }}*{{ row.bwidth }}
        </template>
      </el-table-column>
      <el-table-column prop="date" label="操作" width="49" align="center">
        <template slot-scope="{ row }">
          <span
            class="recommend-list_use"
            @click="$emit('use', row)"
          >使用</span>
        </template>
      </el-table-column>
    </el-table>
    <p class="recommend-list__tips">根据您的单片尺寸和生产工艺要求，给您推荐拼版方式，你可以直接使用推荐的拼版方式。</p>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: []
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend-list ::v-deep {
  .el-table__cell {
    padding: 2px 0;
  }
  .el-table__header .el-table__cell {
    color: $--color-text-primary;
    background-color: $--background-hover;
  }
  .recommend-list_use {
    color: $--form-color-base;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .recommend-list__tips {
    line-height: 1.5;
    font-size: 12px;
    color: $--color-danger;
  }
}
</style>