<template>
  <div class="board-canvas">
    <canvas ref="canvasRef" width="520px" height="507px"></canvas>
  </div>
</template>

<script>

// 滚轮缩放默认配置
const defaultScaleConfig = {
  scale: 1, // 缩放比例
  preScale: 1, // 上一次缩放比例
  scaleStep: 0.1, // 每次缩放系数
  maxScale: 100, // 最大放大倍数
  minScale: 0.01, // 最小缩放倍数
  offsetX: 0, // 中心偏移量X
  offsetY: 0, // 中心偏移量Y
  initBoardRatio: 0.8, // 初始化时板子在画布上的占比
}

export default {
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ctx: null,
      canvas: null,
      drawConfig: {},
      scaleConfig: { ...defaultScaleConfig },
    }
  },
  watch: {
    params: {
      handler: function (val, oldVal) {
        this.drawCanvas()
      },
      deep: true,
      // immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      const canvas = this.$refs.canvasRef
      this.canvas = canvas
      this.ctx = canvas.getContext('2d')

      // 绑定滚轮事件
      this.canvas.addEventListener('wheel', this.handleWheel)

      this.drawCanvas()
    })
  },
  beforeDestroy() {
    // 移除滚轮事件
    this.canvas.removeEventListener("wheel", this.handleWheel)
  },
  methods: {
    setDrawConfig() {
      const { ctx, params } = this
      const {
        board_mode,
        inverted,
        inverted_one,
        length, 
        width, 
        layoutx, 
        layouty, 
        pcs_cols_space, 
        pcs_row_space, 
        sidedirection, 
        sidewidth, 
        gap_cols_space, 
        gap_row_space
      } = params

      const canvasWidth = ctx.canvas.width
      const canvasHeight = ctx.canvas.height
      // 获取 Canvas 的中心点坐标
      const centerX = canvasWidth / 2
      const centerY = canvasHeight / 2
      
      // 按1cm = 10px计算
      const pcsLength = (length || 0) * 10
      const pcsWidth = (width || 0) * 10
      const sidewidthX = parseFloat(['X', 'XY'].includes(sidedirection) ? (sidewidth || 0) : 0)
      const sidewidthY = parseFloat(['Y', 'XY'].includes(sidedirection) ? (sidewidth || 0) : 0)
      const boardLength = layoutx * pcsLength + (layoutx - 1) * (pcs_cols_space || 0) + sidewidthY * 2 + (gap_cols_space || 0) * 2
      const boardWidth = layouty * pcsWidth + (layouty - 1) * (pcs_row_space || 0) + sidewidthX * 2 + (gap_row_space || 0) * 2

      const boardStartX = centerX - boardLength / 2
      const boardStartY = centerY - boardWidth / 2

      let drawConfig = {
        canvasWidth, // 画布宽度
        canvasHeight, // 画布高度
        centerX, // 画布中心 X
        centerY, // 画布中心 Y
        boardMode: board_mode, // 拼版模式 0-常规 1-倒扣
        inverted: inverted, // 拼版方向 0-X方向 1-Y方向
        invertedOne: inverted_one, // 从第一个方向开始 0-无 1-有
        layoutx, // 列
        layouty, // 行
        pcsColSpace: pcs_cols_space|| 0, // 列距
        pcsRowSpace: pcs_row_space || 0, // 行距
        pcsLength, // 单片长度
        pcsWidth, // 单片高度
        boardLength,  // 板子长度
        boardWidth, // 板子宽度
        sidedirection, // 工艺边方向
        sidewidthX, // 工艺边上下宽度
        sidewidthY, // 工艺边左右宽度
        sideRowSpace: gap_row_space || 0, // 工艺边上下间距
        sideColSpace: gap_cols_space || 0, // 工艺边左右间距
        boardStartX, // 板子起始位置 X
        boardStartY, // 板子起始位置 Y
        text: 'F',
        textStyle: '600 36px Arial',
        textColor: '#fff',
        pcsBgColor: '#1d7e18', // 单片背景颜色
        sideColor: '#0905ce', // 工艺边颜色
      }
      
      // 计算文本大小
      this.ctx.font = drawConfig.textStyle
      const metrics = ctx.measureText(drawConfig.text)
      let textWidth = metrics.width
      let textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
      // 对文本进行缩放，确保文本在单片内
      const textScale = Math.min(pcsLength / textWidth, pcsWidth / textHeight, 1)
      drawConfig.textWidth = textWidth
      drawConfig.textHeight = textHeight
      drawConfig.textScale = textScale

      this.drawConfig = drawConfig
    },
    drawCanvas() {
      if (!this.canvas) return
      this.setDrawConfig()

      
      const { centerX, centerY, canvasWidth, canvasHeight, boardLength, boardWidth } = this.drawConfig
      // 当板子长/宽超过画布长/宽时，按比例缩放到画布内
      // const defaultScale = Math.min(canvasWidth / boardLength, canvasHeight / boardWidth, 1)
      // 初始化时，板子默认占画布的0.8大小
      const initBoardRatio = this.scaleConfig.initBoardRatio
      const defaultScale = Math.min(canvasWidth * initBoardRatio / boardLength, canvasHeight * initBoardRatio / boardWidth)
      this.scaleConfig = {
        ...defaultScaleConfig,
        scale: defaultScale,
        preScale: defaultScale,
        offsetX: centerX - (centerX * defaultScale),
        offsetY: centerY - (centerY * defaultScale)
      }
      this.drawScale()
    },
    // 清除画布内容
    clearCanvas() {
      const { canvasWidth, canvasHeight } = this.drawConfig
      // 清除整个画布
      this.ctx.clearRect(0, 0, canvasWidth, canvasHeight)
    },
    // 绘制背景
    drawBg() {
      const { ctx, drawConfig } = this
      const {
        canvasWidth,
        canvasHeight,
        centerX,
        centerY,
        boardStartX, // 板子起始位置 X
        boardStartY, // 板子起始位置 Y
        boardLength,  // 板子长度
        boardWidth, // 板子宽度
      } = drawConfig
      ctx.fillStyle = '#000'
      ctx.fillRect(0, 0, canvasWidth, canvasHeight)

      // 辅助坐标轴
      // ctx.beginPath()
      // ctx.moveTo(0, centerY)
      // ctx.lineTo(canvasWidth, centerY)
      // ctx.moveTo(centerX, 0)
      // ctx.lineTo(centerX, canvasHeight)
      // ctx.strokeStyle = 'red'
      // ctx.stroke()
      // ctx.closePath()
    },
    // 绘制工艺边
    drawSide() {
      const ctx = this.ctx
      const {
        boardStartX, // 板子起始位置 X
        boardStartY, // 板子起始位置 Y
        boardLength,  // 板子长度
        boardWidth, // 板子宽度
        sidewidthX, // 工艺边上下宽度
        sidewidthY, // 工艺边左右宽度
        sideColor, // 工艺边颜色
      } = this.drawConfig

      ctx.save()
      // 转移画布中心到板子左上角
      ctx.translate(boardStartX, boardStartY)
      ctx.fillStyle = sideColor
      // 上边
      ctx.beginPath()
      ctx.fillRect(0, 0, boardLength, sidewidthX)
      ctx.closePath()
      // 上边
      ctx.beginPath()
      ctx.fillRect(0, boardWidth - sidewidthX, boardLength, sidewidthX)
      ctx.closePath()
      // 左边
      ctx.beginPath()
      ctx.fillRect(0, 0, sidewidthY, boardWidth)
      ctx.closePath()
      // 右边
      ctx.beginPath()
      ctx.fillRect(boardLength - sidewidthY, 0, sidewidthY, boardWidth)
      ctx.closePath()
      ctx.restore()
    },
    // 绘制板子
    drawBoard() {
      const {
        boardMode, // 拼版模式 0-常规 1-倒扣
        inverted, // 拼版方向 0-X方向 1-Y方向
        invertedOne, // 从第一个方向开始 0-无 1-有
        layoutx, // 列
        layouty, // 行
        pcsLength, // 单片长度
        pcsWidth, // 单片高度
      } = this.drawConfig

      if (!layoutx || !layouty || !pcsLength || !pcsWidth) return

      // 是否从第一个方向开始
      const isFirstStart = invertedOne == 1
      // 是否旋转
      const isRotate = (x, y) => {
        // Y方向是从最下面一行开始算的，当行数是偶数时，需要错一位计算
        y = layouty % 2 === 0 ? y + 1 : y
        // 倒扣
        if (boardMode == 1) {
          // 偶数列(x从0开始)
          const isEvenX = x % 2 !== 0 
          // 偶数行(y从0开始，方向从下往上)
          const isEvenY = y % 2 !== 0 
          // X方向
          if (inverted == '0') {
            return isFirstStart ?  !isEvenX : isEvenX
          }
          // Y方向
          if (inverted == '1') {
            return isFirstStart ? !isEvenY : isEvenY
          }
        }
        return false
      }
      for (let y = 0; y < layouty; y++) {
        for (let x = 0; x < layoutx; x++) {
          this.drawPcs({
            indexX: x,
            indexY: y, 
            isRotate: isRotate(x, y),
          })
        }
      }
    },
    // 绘制单片
    drawPcs(pcsConfig) {
      const ctx = this.ctx
      const {
        pcsColSpace, // 列距
        pcsRowSpace, // 行距
        pcsLength, // 单片长度
        pcsWidth, // 单片高度
        sidewidthX, // 工艺边上下宽度
        sidewidthY, // 工艺边左右宽度
        sideRowSpace, // 工艺边上下间距
        sideColSpace, // 工艺边左右间距
        boardStartX, // 板子起始位置 X
        boardStartY, // 板子起始位置 Y
        text,
        textStyle,
        textColor,
        textWidth,
        textHeight,
        textScale,
        pcsBgColor
      } = this.drawConfig
      const {
        indexX,
        indexY, 
        isRotate
      } = pcsConfig
      // X坐标：板子起始X坐标 + (当前列数 * 单片宽度) + (当前列数 * 列距) + 左工艺边宽度 + 左工艺边间距
      const startX = boardStartX + (indexX * pcsLength) + (indexX * pcsColSpace) + sidewidthY + sideColSpace
      // Y坐标：板子起始Y坐标 + (当前行数 * 单片高度) + (当前行数 * 行距) + 上工艺边宽度 + 上工艺边间距
      const startY = boardStartY + (indexY * pcsWidth) + (indexY * pcsRowSpace) + sidewidthX + sideRowSpace

      ctx.save()
      // 转移画布中心到当前单片中心
      ctx.translate(startX + pcsLength / 2, startY + pcsWidth / 2)
      if (isRotate) {
        ctx.rotate(Math.PI) // 旋转180度
      }
      ctx.beginPath()
      ctx.fillStyle = pcsBgColor
      ctx.fillRect(-(pcsLength / 2), -(pcsWidth / 2), pcsLength, pcsWidth)
      ctx.save()
      ctx.scale(textScale, textScale)
      ctx.fillStyle = textColor
      ctx.font = textStyle
      ctx.fillText(text, -(textWidth / 2), textHeight / 2)
      ctx.restore()
      ctx.closePath()
      ctx.restore()
    },
    // 滚轮缩放
    handleWheel(event) {
      // 防止页面滚动
      event.preventDefault()
      // 当前鼠标点击的坐标
      const eventOffsetX = event.offsetX
      const eventOffsetY = event.offsetY

      const {centerX, centerY } = this.drawConfig
      const { scale, scaleStep, preScale, offsetX, offsetY, maxScale, minScale } = this.scaleConfig
      // 缩放系数
      const scaleFactor = 1 + (event.wheelDelta > 0 ? scaleStep : -scaleStep)
      // 缩放大小
      this.scaleConfig.scale = Math.max(minScale, Math.min(parseFloat((scale * scaleFactor).toFixed(4)), maxScale))
      // 以当前鼠标坐标为原点缩放
      // this.scaleConfig.offsetX = eventOffsetX - ((eventOffsetX - offsetX) * this.scaleConfig.scale) / preScale
      // this.scaleConfig.offsetY = eventOffsetY - ((eventOffsetY - offsetY) * this.scaleConfig.scale) / preScale
      
      // 以画布中心为原点缩放
      this.scaleConfig.offsetX = centerX - ((centerX - offsetX) * this.scaleConfig.scale) / preScale
      this.scaleConfig.offsetY = centerY - ((centerY - offsetY) * this.scaleConfig.scale) / preScale

      // 上次缩放大小
      this.scaleConfig.preScale = this.scaleConfig.scale

      this.drawScale()
    },
    // 缩放绘制
    drawScale() {
      const { scale, offsetX, offsetY } = this.scaleConfig
      this.clearCanvas()
      this.drawBg()
      this.ctx.save()
      this.ctx.translate(offsetX, offsetY)
      this.ctx.scale(scale, scale)
      this.drawSide()
      this.drawBoard()
      this.ctx.restore()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>