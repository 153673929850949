<!-- 阻焊颜色 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: String,
      default: '白色'
    },
    // 阻焊颜色
    color: {
      type: String,
      default: '绿色'
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('charcolor')
    },
    options() {
      const { fields, color } = this
      let options = fields.options
      
      return options.map(item => ({...item, disabled: (item.value == '白色' && color == '白色') || (item.value == '黑色' && color != '白色') }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    color(color) {
      if (this.initPcbParamsChange) return
      this.val = color == '白色' ? '黑色' : '白色'
      this.handleEmit()
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>