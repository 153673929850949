<!-- 板子厚度 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
    :valueConfig="valueConfig"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ Number(item.label).toFixed(1) }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 2
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 外层铜厚
    copper: {
      type: Number,
      default: 1
    },
    // 内层铜厚
    insidecopper: {
      type: Number,
      default: 1
    },
    // 阻焊颜色
    color: {
      type: String,
      default: '绿色'
    },
    // 半孔
    bankong: {
      type: Number,
      default: 0
    },
    // 包边
    baobian: {
      type: Number,
      default: 0
    },
    // 最小孔径
    vias: {
      type: Number,
      default: 0.3
    },
    // 面积
    area: {
      type: Number,
      default: 0
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    },
    // 获取配置的默认初始化参数中
    initDefaultPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('bheight')
    },
    options() {
      const { fields, blayer, copper, insidecopper, color, bankong, baobian, area, vias, initPcbParamsChange, initDefaultPcbParamsChange } = this
      const { min, max } = this.computePcbHeight(blayer)
      const options = fields.options

      let disabledOptions = options.filter(item => !(item >= min && item <= max))
      if (area < 5 && (bankong > 0 || baobian > 0) && (blayer == 1 || blayer == 2)) {
        disabledOptions.push(1.2)
      } else if (copper == 2 && area <= 3 && (blayer == 1 || blayer == 2) && color == '绿色') {
        disabledOptions.push(0.6, 0.8, 1.0, 1.2)
      } else if (copper == 2 && area <= 3 && (blayer == 1 || blayer == 2) && color != '绿色') {
        disabledOptions.push(0.6, 0.8, 1.0, 1.2)
      } else if (area <= 3 && color != '绿色' && (blayer == 1 || blayer == 2) && copper == 1 && vias < 0.25) {
        disabledOptions.push(0.6, 0.8, 1.0, 1.2, 2.0)
      }
      if (blayer == 2 && copper >= 3) {
        disabledOptions.push(0.6, 3.2)
      }
      if (blayer == 4 && (copper == 3 || insidecopper == 3)) {
        disabledOptions.push(0.6, 0.8, 3.2)
      }
      if (blayer == 4 && (copper == 4 || insidecopper == 4)) {
        disabledOptions.push(0.6, 0.8, 1.0, 1.2, 3.2)
      }
      if (blayer == 6 && (copper >= 3 || insidecopper >= 3)) {
        disabledOptions.push(0.6, 0.8, 1.0, 1.2, 3.2)
      }

      // 初始化且板子厚度值为禁用选项时，置空
      if ((initPcbParamsChange || initDefaultPcbParamsChange) && disabledOptions.includes(this.val)) {
        this.val = ''
        this.handleEmit()
        if (initDefaultPcbParamsChange) {
          alertFieldsTips(this.fields.label)
        }
      }

      return options.map(i => ({ value: i, label: i, disabled: disabledOptions.includes(i) }))
    },
    valueConfig() {
      return { key: 'bheight', value: { 16: 2.0, 18: 2.5, 20: 2.5 }[this.blayer] || 1.6 }
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    blayer(blayer) {
      const { def } = this.computePcbHeight(blayer)
      if (this.val != def && !this.initDefaultPcbParamsChange) {
        this.changeVal(def)
      }
    },
    copper(copper) {
      const { val, blayer, color, area } = this
      const flag = [
        copper == 2 && area <= 3 && (blayer == 1 || blayer == 2) && color != '绿色' && val >= 0.6 && val <= 2.0,
        copper == 2 && area <= 3 && (blayer == 1 || blayer == 2) && color == '绿色' && val >= 0.6 && val <= 1.2,
        copper <= 2 && blayer == 2 && (val == 3.0 || val == 2.5),
        copper <= 2 && blayer > 2 && blayer <= 8 && val == 3.0,
        copper > 2 && blayer == 2 && val == 0.6,
        copper == 3 && blayer == 4 && val <= 0.8,
        copper == 4 && blayer == 4 && val <= 1.2,
        copper > 2 && blayer == 6 && val <= 1.2
      ].some(i => i)
      if (flag) {
        this.changeVal(1.6)
      }
    },
    insidecopper(insidecopper) {
      const { val, blayer } = this
      const flag = [
        insidecopper == 3 && blayer == 4 && val <= 0.8,
        insidecopper == 4 && blayer == 4 && val <= 1.2,
        insidecopper > 2 && blayer == 6 && val <= 1.2
      ].some(i => i)
      if (flag) {
        this.changeVal(1.6)
      }
    },
    color(color) {
      const { val, blayer, copper, area, vias } = this
      const flag = [
        copper == 2 && area <= 3 && (blayer == 1 || blayer == 2) && val >= 1.6 && val <= 2.0,
        copper == 1 && area <= 3 && (blayer == 1 || blayer == 2) && color != '绿色' && val != 1.6 && vias < 0.25
      ].some(i => i)
      if (flag) {
        this.changeVal(1.6)
      }
    },
    vias(vias) {
      const { val, blayer, copper, area, color } = this
      const flag = [
        vias < 0.25 && (blayer == 1 || blayer == 2) && copper == 1 && area <= 3 && color != '绿色' && val != 1.6
      ].some(i => i)
      if (flag) {
        this.changeVal(1.6)
      }
    },
    area(area) { 
      const { val, blayer, copper, color, bankong, baobian } = this
      var { def } = this.computePcbHeight(blayer)
      if ((bankong > 0 || baobian > 0) && area < 5 && (blayer == 1 || blayer == 2)) {
        this.val = def
        this.handleEmit()
      } else if (copper == 2 && area <= 3 && (blayer == 1 || blayer == 2) && val >= 0.6 && val <= 1.2 && color != '绿色') {
        this.changeVal(1.6)
      }
    },
    bankong() { 
      this.bankongOrBaobianChange()
    },
    baobian() { 
      this.bankongOrBaobianChange()
    },
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
      this.isShowError(false)
    },
    changeVal(val) {
      console.log('changeVal', val)
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    },
    // 半孔/包边改变
    bankongOrBaobianChange() {
      if (this.initPcbParamsChange) return
      const { bankong, baobian, blayer, area } = this
      const { def } = this.computePcbHeight(blayer)
      if ((bankong > 0 || baobian > 0) && area < 5 && (blayer == 1 || blayer == 2)) { 
        if (this.val != 1.6) {
          alertFieldsTips(this.fields.label)
        }
        this.val = def
      }
      this.handleEmit()
    },
    // 计算板子厚度最小 最大 默认值
    computePcbHeight(blayer) {
      const { area, copper, insidecopper, bankong, baobian } = this
      const flag = area < 5 && (bankong > 0 || baobian > 0)
      const defaultData = { min: 0.4, max: 3.0, def: 1.6 }
      const data = {
        1: { min: flag ? 1.0 : 0.6, max: flag ? 1.6 : 2.0 },
        2: { min: flag ? 1.0 : 0.6, max: flag ? 1.6 : (copper > 2 || insidecopper > 2) ? 3.2 : 2.0 },
        4: { min: 0.6, max: 2.0 },
        6: { min: 0.8, max: 2.5 },
        8: { min: 1.0, max: 2.5 },
        10: { min: 1.2, max: 2.5 },
        12: { min: 1.6, max: 2.5 },
        14: { min: 1.6, max: 3.0 },
        16: { min: 1.8, max: 3.2, def: 2.0 },
        18: { min: 2.0, max: 3.2, def: 2.5 },
        20: { min: 2.0, max: 3.2, def: 2.5 }
      }[blayer]

      return {...defaultData, ...data}
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
</style>