<!-- 叠层结构 -->
<template>
  <BaseFormItem
    class="pressing"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" :class="{ 'has-options': options.length > 0 }" @change="handleEmit">
      <!-- 固定选项 -->
      <el-radio
        border
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio>
      <!-- 根据板子信息获取的叠层结构选项 -->
      <el-radio
        border
        v-for="(item, index) in options"
        :key="index"
        :label="item.press_no_and_pp"
      >{{ `华秋${item.press_name}` }}</el-radio>
      <!-- 计算选中的推荐结构 -->
      <el-badge v-if="pressing_computer_id" value="计算" class="pressing__badge">
        <el-radio
          border
          class="pressing__computer"
          :label="`${pressing_computer_id}__${pressing_computer_pp}`"
        >
          {{ `华秋${pressing_computer_pp}` }}
          &nbsp;
          <el-button 
            type="text" 
            style="padding: 0"
            @click="handleOpenDialog(true)"
          > 
            | 查看
          </el-button>
        </el-radio>
      </el-badge>
    </BaseRadioGroup>

    <span class="pressing__btns">
      <BaseTextButton v-if="options.length > 0" @click="handleOpenDialog(false)">
        阻抗匹配叠层计算
      </BaseTextButton>
      <!-- 阻抗叠层试算弹框 -->
      <PressingDialog 
        ref="PressingDialogRef"
        :params="params" 
        @confirm="handleConfirm"
      />
      <!-- 查看全部叠层弹框 -->
      <PressingViewDialog 
        v-if="options.length > 0"
        :params="params" 
        :list="options"
        :pressingNo="val"
      />
      <!-- 阻抗叠层试算 -->
      <BaseLink v-if="[4, 6].includes(this.params.blayer)" @click="handleGoImpedance">阻抗叠层试算</BaseLink>
    </span>
  </BaseFormItem>
</template>

<script>
import PressingDialog from '../components/pressingDialog/index'
import PressingViewDialog from '../components/pressingViewDialog'

import { debounce } from '@@/utils/utils'
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'
import { getPressingOptions } from '@@/api/pcb'
import CONFIG from '@@/config'
import qs from 'qs'

export default {
  props: {
    value: {
      type: String,
      default: '无需求'
    },
    params: {
      type: Object,
      defalut: () => ({})
    },
    pressingData: {
      type: Object,
      defalut: () => ({})
    }
  },
  components: {
    PressingDialog,
    PressingViewDialog
  },
  data() {
    return {
      val: this.value,
      options: [], // 根据板子信息获取的叠层结构选项
      debounceGetOptions: debounce(this.getOptions, 300),
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
      pressing_computer_id: '', // 计算id
      pressing_computer_pp: '', // 当前选中的计算叠层结构选项
      viewPressingData: {}, // 查看的叠层结构数据
    }
  },
  computed: {
    fields() {
      return getFR4Fields('pressing')
    },
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    params: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.debounceGetOptions()
          this.handleResetCompute()
        }
      },
      deep: true
    },
    pressingData: {
      handler(newVal) {
        if (newVal) {
          // 修改时，计算选项与查看数据
          const { pressing_computer_id, pressing_result } = newVal || {}
          this.pressing_computer_id = pressing_computer_id
          this.pressing_computer_pp = pressing_result.pressing_with_pp
          this.viewPressingData = newVal
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getOptions()
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
      this.isShowError(false)
    },
    // 跳转到阻抗叠层试算页
    handleGoImpedance() {
      const params = qs.stringify(this.params)
      window.open(`${CONFIG.HQPCB_HOST}/quote/impedance?${params}`)
    },
    getOptions() {
      getPressingOptions(this.params)
        .then(res => {
          if (!res) return
          const { status, data = [], msg } = res
          if (status == 1) {
            const val = this.val

            this.options = data
            if (!val) {
              // 当叠层结构无值时,默认选中无需求
              this.val = '无需求'
              this.handleEmit()
            } else if (
              !['无需求', '按客户指定叠层'].includes(val) && 
              data.every(i => i.press_no_and_pp !== val) && 
              (!val.includes('__') || (!this.pressing_computer_pp && val.includes('__')))
            ) {
              // 当叠层结构的值不在选项中时,清空该值
              this.val = ''
              this.handleEmit()
              alertFieldsTips(this.fields.label)
            }
          } else {
            this.$message.error(msg)
          }
        })
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    },
    // 重置计算的选项和结果
    handleResetCompute() {
      this.pressing_computer_id = ''
      this.pressing_computer_pp = ''
      this.viewPressingData = {}
    },
    // 打开计算叠层结构弹框
    handleOpenDialog(isView) {
      const pressingResult = isView && this.viewPressingData.pressing_result ? this.viewPressingData.pressing_result : false
      this.$refs.PressingDialogRef.open(pressingResult)
    },
    handleConfirm(data) {
      const { pressing_computer_id, pressing_computer_pp, pressing_data } = data
      this.pressing_computer_id = pressing_computer_id
      this.pressing_computer_pp = pressing_computer_pp
      this.val = `${pressing_computer_id}__${pressing_computer_pp}`
      this.viewPressingData = { pressing_computer_id, pressing_result: pressing_data }
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
.pressing ::v-deep {
  .base-radio-group {
    &.has-options {
      margin-bottom: -10px;
      .el-radio.is-bordered {
        margin-bottom: 10px;
      }
    }
    .el-radio.is-bordered {
      margin-left: 0;
      margin-right: 10px;
    }
  }
  .pressing__badge {
    .el-badge__content.is-fixed {
      top: 2px;
      right: 29px;
    }
  }
  .pressing__computer {
    .el-button {
      color: $--form-color-base;
    }
  }
}
</style>