<!-- 最小孔径 -->
<template>
  <BaseFormItem
    :unit="fields.unit"
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <img slot="other" :src="require('@@/assets/img/pcb/vias_view.jpg')" />
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: Number,
      default: 6
    },
    // HDI(盲埋孔)
    blind: {
      type: Number,
      default: 0
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 板子厚度
    bheight: {
      type: [Number, String]
    },
    // 外层铜厚
    copper: {
      type: Number,
      default: 1
    },
    // 内层铜厚
    insidecopper: {
      type: Number,
      default: 1
    },
    // 阻焊颜色
    color: {
      ttype: String,
      default: '绿色'
    },
    // 面积
    area: {
      type: Number,
      default: 0
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('vias')
    },
    options() {
      const { val, fields, blayer, bheight, copper, insidecopper, color, area } = this
      const options = fields.options
      // 禁用 板子厚度 / 最小孔径 ＞ 10 的选项
      const heightToViasRatio = options.map(item => item.value).filter(value => bheight / value > 10)

      let disabledOptions = [
        {
          flag: blayer == 1 && ((bheight >= 0.6 && bheight <= 1.2) || (bheight >= 1.6 && bheight <= 2.0)),
          option: [0.25, 0.2, 0.15]
        },
        {
          flag: (blayer == 2 && (bheight >= 1.6 && bheight <= 2.0)) || (blayer >= 4 && bheight >= 2.0),
          option: [0.15]
        },
        {
          flag: (blayer == 1 || blayer == 2) && area <= 3 && ((copper == 2 && bheight == 2.0 && color == '绿色') || (copper == 1 && bheight != 1.6 && color != '绿色') || (copper == 1 && color == '黄色')),
          option: [0.15, 0.2]
        },
        {
          flag: blayer >= 2 && blayer < 8 && (copper > 2 || insidecopper > 2),
          option: [0.15, 0.2, 0.25]
        }
      ]
        .filter(i => i.flag)
        .reduce((disableds, item) => [...new Set([...disableds, ...item.option])], heightToViasRatio)

      // 当所有选项都被禁用时，保留 0.3 选项
      if (options.length === disabledOptions.length) {
        disabledOptions = disabledOptions.filter(item => item !== 0.3)
      }
      
      // 如果当前项被禁用，选中第一个可选的选项
      if (disabledOptions.includes(val)) {
        this.changeVal((options.find(item => !disabledOptions.includes(item.value)) || {}).value)
      }
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    blind(blind) { 
      if (blind > 0 && this.val != 0.2) {
        this.changeVal(0.2)
      }
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>