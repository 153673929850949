<!-- 拼版款数 -->
<template>
  <BaseFormItem
    class="pbnum"
    :unit="fields.unit"
    :label="fields.label"
  >
    <BaseInput
      v-model="val"
      width="60px"
      @input="val => $emit('input', val)"
      @change="val => $emit('change', val)"
    ></BaseInput>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('zknum')
    },
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
</style>