var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pressing-params"},[_c('div',{staticClass:"pressing-params__btns"},[_c('div',[_c('el-input',{attrs:{"size":"mini","placeholder":"行数"},model:{value:(_vm.addRows),callback:function ($$v) {_vm.addRows=$$v},expression:"addRows"}},[_c('template',{slot:"append"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.handleAdd}},[_vm._v("添加")])],1)],2),_c('el-button',{attrs:{"size":"mini","disabled":_vm.disabledDelBtn},on:{"click":_vm.handleDel}},[_vm._v("删除")]),_c('el-button',{attrs:{"size":"mini","disabled":_vm.disabledCopyBtn},on:{"click":_vm.handleCopy}},[_vm._v("复制")]),_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":_vm.handleValidate}},[_vm._v("计算")])],1),_c('p',[_c('BaseTipPopover',{attrs:{"width":"810px","content":"\n          1、请输入您需要的阻抗值及线宽线距，系统将根据您的要求匹配出合适的叠层结构，其中线宽间距可能会有调整：\n\n          * 若匹配结果的线宽、线距、到铜距离的计算值标红，有可能超出我司制程能力；\n\n          * 若匹配结果的阻抗值计算结果标红，是与您要求的阻抗值相差±2ohm\n\n          2、目前我司可匹配4和6层板的叠层结构\n        "}})],1)]),_c('el-table',{staticClass:"pressing-params__list pressing-table",attrs:{"border":"","data":_vm.params}},[_c('el-table-column',{attrs:{"label":"选择"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [_c('el-radio',{attrs:{"label":$index},model:{value:(_vm.currentParamsIndex),callback:function ($$v) {_vm.currentParamsIndex=$$v},expression:"currentParamsIndex"}})]}}])}),_c('el-table-column',{attrs:{"label":"要求阻抗（ohm）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [_c('el-input-number',{class:{ 'error': _vm.emptyParams[$index] && _vm.emptyParams[$index].includes('demand') },attrs:{"size":"mini","placeholder":"请输入","precision":0,"min":40,"max":130,"controls":false},model:{value:(_vm.params[$index].demand),callback:function ($$v) {_vm.$set(_vm.params[$index], "demand", $$v)},expression:"params[$index].demand"}})]}}])}),_c('el-table-column',{attrs:{"label":"阻抗层"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [_c('el-select',{attrs:{"size":"mini","popper-class":"pressing-select","placeholder":"请选择"},on:{"change":function (val) { return _vm.params[$index] = Object.assign(_vm.params[$index], { top: '', bot: '' }); }},model:{value:(_vm.params[$index].layer),callback:function ($$v) {_vm.$set(_vm.params[$index], "layer", $$v)},expression:"params[$index].layer"}},_vm._l((_vm.blayerOptions),function(item){return _c('el-option',{key:item,attrs:{"value":item,"label":item}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"阻抗模式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [_c('el-select',{attrs:{"size":"mini","popper-class":"pressing-select","placeholder":"请选择"},on:{"change":function () { return _vm.handleModeChange(_vm.params[$index]); }},model:{value:(_vm.params[$index].mode),callback:function ($$v) {_vm.$set(_vm.params[$index], "mode", $$v)},expression:"params[$index].mode"}},_vm._l((_vm.modeOptions),function(label,value){return _c('el-option',{key:value,attrs:{"value":Number(value),"label":label}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"上参考层"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [(_vm.params[$index].top === '/')?_c('div',[_vm._v("/")]):_c('el-select',{attrs:{"size":"mini","popper-class":"pressing-select","placeholder":"请选择"},model:{value:(_vm.params[$index].top),callback:function ($$v) {_vm.$set(_vm.params[$index], "top", $$v)},expression:"params[$index].top"}},_vm._l((_vm.topOptions(row, $index)),function(item){return _c('el-option',{key:item,attrs:{"value":item,"label":item}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"下参考层"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [(_vm.params[$index].bot === '/')?_c('div',[_vm._v("/")]):_c('el-select',{attrs:{"size":"mini","popper-class":"pressing-select","placeholder":"请选择"},model:{value:(_vm.params[$index].bot),callback:function ($$v) {_vm.$set(_vm.params[$index], "bot", $$v)},expression:"params[$index].bot"}},_vm._l((_vm.botOptions(row, $index)),function(item){return _c('el-option',{key:item,attrs:{"value":item,"label":item}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"原始线宽（mil）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [_c('el-input-number',{class:{ 'error': _vm.emptyParams[$index] && _vm.emptyParams[$index].includes('original_line_width') },attrs:{"size":"mini","placeholder":"请输入","precision":2,"min":3,"max":80,"controls":false},model:{value:(_vm.params[$index].original_line_width),callback:function ($$v) {_vm.$set(_vm.params[$index], "original_line_width", $$v)},expression:"params[$index].original_line_width"}})]}}])}),_c('el-table-column',{attrs:{"label":"原始线距（mil）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [(![1, 3].includes(_vm.params[$index].mode))?_c('div',[_vm._v("/")]):_c('el-input-number',{class:{ 'error': _vm.emptyParams[$index] && _vm.emptyParams[$index].includes('original_line_space') },attrs:{"size":"mini","placeholder":"请输入","precision":2,"min":3.8,"max":20,"controls":false},model:{value:(_vm.params[$index].original_line_space),callback:function ($$v) {_vm.$set(_vm.params[$index], "original_line_space", $$v)},expression:"params[$index].original_line_space"}})]}}])}),_c('el-table-column',{attrs:{"label":"原到铜距离（mil）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [(![2, 3].includes(_vm.params[$index].mode))?_c('div',[_vm._v("/")]):_c('el-input-number',{class:{ 'error': _vm.emptyParams[$index] && _vm.emptyParams[$index].includes('original_copper_space') },attrs:{"size":"mini","placeholder":"请输入","precision":2,"min":4,"max":50,"controls":false},model:{value:(_vm.params[$index].original_copper_space),callback:function ($$v) {_vm.$set(_vm.params[$index], "original_copper_space", $$v)},expression:"params[$index].original_copper_space"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }