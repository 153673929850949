<!-- HDI(盲埋孔) -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    // 是否是HDI板
    isHdi: {
      type: Boolean,
      default: false
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('blind')
    },
    options() {
      const { value, isHdi, blayer, fields } = this
      let options = [...fields.options]
      // 最大阶数展示
      const maxBlindVal = (blayer - 2) / 2
      if (value > 3) {
        options.push({
          value: value,
          label: `${value} 阶`,
        })
      }

      return options.map(item => ({ ...item, disabled: (item.value > 1 && item.value > maxBlindVal) || (isHdi && item.value === 0) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    blayer(blayer) {
      // 板子层数对阶数的限制
      const maxBlindValMap = { 4: 1, 6: 2, 8: 3, 10: 4 }
      if (maxBlindValMap[blayer] && this.value > maxBlindValMap[blayer]) {
        this.changeVal(1)
      }
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>