<!-- TG值 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('board_tg')
    },
    options() {
      const { val, blayer } = this
      let options = [
        {
          flag: blayer == 4,
          option: 'TG130'
        },
        {
          flag: [4, 6, 8].includes(blayer),
          option: 'TG150'
        },
        {
          flag: blayer >= 6,
          option: 'TG170'
        }
      ].filter(i => i.flag).map(i => i.option)

      // 当TG值有值且选项中没有该值时，选项中添加该值
      if (val && !options.includes(val)) {
        options.push(val)
      }
      
      return options.map(i => ({ value: i, label: i }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    blayer(blayer) {
      this.blayerChange()
    }
  },
  created() {
    this.blayerChange()
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    },
    blayerChange() {
      if (this.initPcbParamsChange) return
      const { blayer, options } = this
      this.val = (options[0] || {}).value
      this.handleEmit()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>