<!-- 3D Iframe -->
<template>
  <div v-loading="loading" class="iframe-3d quote-container__content">
    <iframe :src="iframeSrc" width="410" height="400" allowfullscreen="true"></iframe>
  </div>
</template>

<script>
import { HQPCB_HOST } from '@@/api/pcb'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    analyzeId: {
      type: String,
      default: ''
    },
    analyzeKey: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    iframeSrc() {
      const { analyzeId, analyzeKey } = this
      // return `http://localhost:5173/iframeviewer?analyzeId=${analyzeId}&key=${analyzeKey}`
      return `${HQPCB_HOST}/gerberviewer/iframeviewer?id=${analyzeId}&key=${analyzeKey}`
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe-3d {
  box-sizing: border-box;
  margin-left: 10px;
  padding: 0;
  width: 410px;
  height: 400px;
  overflow: hidden;
}
</style>