<!-- 拼版工具弹框 -->
<template>
  <el-dialog
    width="1080px"
    top="100px"
    custom-class="board-dialog"
    append-to-body
    :visible="isShow"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div slot="title" class="board-dialog__title">
      连片(华秋代拼)
      <BaseLink href="https://www.hqpcb.com/help/p_1876.html" target="_blank">
        操作说明
      </BaseLink>
    </div>
    <div class="board-dialog__content">
      <el-form 
        ref="formRef"
        size="small"
        class="board-dialog__form"
        label-width="90px"
        label-position="left"
        :model="form"
      >
        <BaseFormItem
          class="board-dialog__form__size"
          isShowRequiredIcon
          label="单片尺寸"
          :labelTips="labelTips.size"
        >
          <BaseInputNumber
            v-model="form.length"
            class="board-dialog__form__inputNumber"
            width="84px"
            placeholder="长/x"
            :controls="false"
            :precision="2"
            @change="handleSizeChange"
          />
          <span class="linker">×</span>
          <BaseInputNumber
            v-model="form.width"
            class="board-dialog__form__inputNumber"
            width="84px"
            placeholder="宽/y"
            :controls="false"
            :precision="2"
            @change="handleSizeChange"
          />
          <span class="board-dialog__form__unit">cm</span>
          <template slot="other">
            <BaseButton 
              round 
              plain 
              class="btn"
              :loading="listLoading"
              @click="getRecommendList"
            >
              {{ recommendBtnText }}
            </BaseButton>
          </template>
        </BaseFormItem>
        <!-- 推荐拼版列表 -->
        <RecommendList 
          v-if="recommendList.length"
          :list="recommendList" 
          @use="handleUseRecommend" 
        />
        <div class="board-dialog__form__divider"></div>

        <BaseFormItem
          isShowRequiredIcon
          label="拼版模式"
          labelTipsWidth="556px"
          :labelTips="labelTips.board_mode"
        >
          <!-- 拼版模式 -->
          <BaseSelect 
            v-model="form.board_mode" 
            placeholder="请选择" 
            style="width: 84px;"
            @change="handleBoardModeChange"
          >
            <el-option label="常规" value="0" />
            <el-option label="倒扣" value="1" />
          </BaseSelect>
          <!-- 拼版方向 -->
          <el-radio-group 
            v-model="form.inverted" 
            class="board-dialog__form__radio"
            :disabled="disabledInverted"
          >
            <el-radio label="0">X方向</el-radio>
            <el-radio label="1">Y方向</el-radio>
          </el-radio-group>
          <BaseCheckbox 
            v-model="form.inverted_one"
            :true-label="1"
            :false-label="0"
            :disabled="disabledInvertedOne"
          >
            从第一个方向开始
          </BaseCheckbox>
        </BaseFormItem>
        <div class="board-dialog__form__divider"></div>

        <el-form-item label="拼版规则" required>
          <div class="board-dialog__form__multiLine">
            <el-form-item prop="layoutx" label="列" label-width="50px">
              <BaseInputNumber
                v-model="form.layoutx"
                class="board-dialog__form__inputNumber"
                width="84px"
                :controls="false"
                :precision="0"
                @change="handleLayoutxChange"
              />
            </el-form-item>
            <el-form-item prop="layouty" label="行" label-width="50px">
              <BaseInputNumber
                v-model="form.layouty"
                class="board-dialog__form__inputNumber"
                width="84px"
                :controls="false"
                :precision="0"
                @change="handleLayoutyChange"
              />
            </el-form-item>
          </div>
          <div class="board-dialog__form__multiLine">
            <el-form-item prop="pcs_cols_space" label="列距" label-width="50px">
              <BaseInputNumber
                v-model="form.pcs_cols_space"
                class="board-dialog__form__inputNumber"
                width="84px"
                :controls="false"
                :precision="1"
                :disabled="!hasSideX || form.layoutx == 1"
                @blur="() => handleSpaceBlur('pcs_cols_space')"
              />
              <span class="board-dialog__form__unit">mm</span>
            </el-form-item>
            <el-form-item prop="pcs_row_space" label="行距" label-width="50px">
              <BaseInputNumber
                v-model="form.pcs_row_space"
                class="board-dialog__form__inputNumber"
                width="84px"
                :controls="false"
                :precision="1"
                :disabled="!hasSideY || form.layouty == 1"
                @blur="() => handleSpaceBlur('pcs_row_space')"
              />
              <span class="board-dialog__form__unit">mm</span>
            </el-form-item>
          </div>
        </el-form-item>
        <div class="board-dialog__form__divider"></div>

        <BaseFormItem
          label="工艺边框"
          :labelTips="labelTips.sidewidth"
        >
          <BaseInput 
            v-model="form.sidewidth" 
            width="190px" 
            class="board-dialog__form__sidewidth"
            :disabled="disabledSidewidthInput"
            @change="handleSidewidthChange"
          >
            <BaseSelect 
              slot="prepend"
              v-model="form.sidedirection" 
              width="80px"
              placeholder="请选择"
              @change="handleSidedirectionChange"
            >
              <el-option
                v-for="item in sidedirectionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </BaseSelect>
          </BaseInput>
          <span class="board-dialog__form__unit">mm</span>
        </BaseFormItem>
        <el-form-item label="工艺边间距">
          <div class="board-dialog__form__multiLine">
            <el-form-item label="左右" label-width="50px">
              <BaseInputNumber
                v-model="form.gap_cols_space"
                class="board-dialog__form__inputNumber"
                width="84px"
                :controls="false"
                :precision="1"
                :disabled="!hasSideY"
                @blur="() => handleSpaceBlur('gap_cols_space')"
              />
              <span class="board-dialog__form__unit">mm</span>
            </el-form-item>
            <el-form-item label="上下" label-width="50px">
              <BaseInputNumber
                v-model="form.gap_row_space"
                class="board-dialog__form__inputNumber"
                width="84px"
                :controls="false"
                :precision="1"
                :disabled="!hasSideX"
                @blur="() => handleSpaceBlur('gap_row_space')"
              />
              <span class="board-dialog__form__unit">mm</span>
            </el-form-item>
          </div>
        </el-form-item>
        <div class="board-dialog__form__divider"></div>

        <el-form-item label="拼版后尺寸">
          {{ computePcbSize.length || 0 }} x {{ computePcbSize.width || 0 }} cm
          <p class="board-dialog__content__tips">
            拼版后最小尺寸需要大于5*5，{{ pcbParams.blayer >= 4 ? '最大尺寸须小于 50*40cm' : '最大尺寸长或宽不能同时超过 50cm，须小于 60*50cm' }}
          </p>
        </el-form-item>
      </el-form>
      <div class="board-dialog__canvas">
        <BoardCanvas v-if="isShow" ref="boardCanvasRef" :params="form" />
      </div>
    </div>

    <div slot="footer" class="board-dialog__footer">
      <BaseButton round size="medium" @click="handleSave">
        保存
      </BaseButton>
      <BaseButton round size="medium" class="board-dialog__footer__defaultBtn" @click="handleCancel">
        取消
      </BaseButton>
    </div>
  </el-dialog>
</template>

<script>
// 推荐拼版列表
import RecommendList from './recommendList'
// 拼版示例
import BoardCanvas from './boardCanvas'

import { FR4_BOARD_DEFAULT_FORM } from '@@/utils/defaultForm'
import { getFR4Fields } from '@@/utils/getFields'
import { computePcbSize } from '@@/utils/pcbParamsFormat'
import { getBoardSets } from '@@/api/pcb'

export default {
  props: {
    pcbParams: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    RecommendList,
    BoardCanvas
  },
  data() {
    return {
      isShow: false,
      listLoading: false, // 推荐拼版loading
      recommendList: [], // 推荐拼版列表
      form: { ...FR4_BOARD_DEFAULT_FORM },
      preData: {},
      labelTips: {
        size: getFR4Fields('length').labelTips,
        sidewidth: getFR4Fields('sidewidth').labelTips,
        board_mode: `
          <div class='label-tips-img'><img src='${require(`@@/assets/img/pcb/label/board_mode.png`)}' style='width: 536px;' /></div>
        `
      }
    }
  },
  computed: {
    // 推荐拼版按钮文案
    recommendBtnText() {
      const { listLoading, recommendList } = this
      if (listLoading) return '推荐拼版中'
      if (recommendList.length) return '重新推荐'
      return '推荐拼版'
    },
    // 工艺边框方向选项
    sidedirectionOptions() {
      return getFR4Fields('sidewidth').options
    },
    // 禁用 X方向 Y方向
    disabledInverted() {
      const isDisabled = this.form.board_mode === '0'
      // 拼版模式为常规时，重置 X方向 Y方向
      if (isDisabled) {
        this.form.inverted = ''
      }
      return isDisabled
    },
    // 禁用 从第一个方向开始
    disabledInvertedOne() {
      const isDisabled = this.form.board_mode === '0'
      // 拼版模式为常规时，重置 从第一个方向开始
      if (isDisabled) {
        this.form.inverted_one = 0
      }
      return isDisabled
    },
    // 禁用 工艺边框输入框
    disabledSidewidthInput() {
      return this.form.sidedirection === '无'
    },
    // 有左右工艺边框
    hasSideY() {
      return ['Y', 'XY'].includes(this.form.sidedirection)
    },
    // 有上下工艺边框
    hasSideX() {
      return ['X', 'XY'].includes(this.form.sidedirection)
    },
    // 拼版后尺寸
    computePcbSize() {
      return computePcbSize({ ...this.form, units: 2 })
    }
  },
  watch: {
    // 拼版模式 方向处理
    'form.inverted'(newVal) {
      const data = {
        '0': { invertedx: 1, invertedy: 0 },
        '1': { invertedx: 0, invertedy: 1 }
      }[newVal] || { invertedx: 0, invertedy: 0 }
      this.form = Object.assign(this.form, data)
    }
  },
  methods: {
    open(preData) {
      this.preData = preData
      this.initForm()
      this.isShow = true
    },
    handleClose() {
      this.handeReset()
      this.isShow = false
    },
    // 初始化数据
    initForm() {
      const { form, pcbParams } = this
      const formData = Object.keys(form).reduce((data, key) => {
        data[key] = !!pcbParams[key] && pcbParams[key] !== 0 ? pcbParams[key] : form[key]
        return data
      }, {})
      // X方向
      if (pcbParams.invertedx == 1 && pcbParams.invertedy == 0) {
        formData.inverted = '0'
      }
      // Y方向
      if (pcbParams.invertedx == 0 && pcbParams.invertedy == 1) {
        formData.inverted = '1'
      }
      this.form = Object.assign(this.form, formData)

      this.handleSizeChange()
    },
    // 重置数据
    handeReset() {
      this.form = Object.assign(this.form, FR4_BOARD_DEFAULT_FORM)
      this.recommendList = []
    },
    // 拼版模式改变
    handleBoardModeChange(val) {
      // 默认选中X方向
      this.form.inverted = this.form.inverted || '0'
    },
    // 拼版规则改变
    handleLayoutxChange(val) {
      if (val <= 1) {
        this.form.pcs_cols_space = 0
      }
    },
    // 拼版规则改变
    handleLayoutyChange(val) {
      if (val <= 1) {
        this.form.pcs_row_space = 0
      }
    },
    // 工艺边方向改变
    handleSidedirectionChange(val) {
      const { disabledSidewidthInput, hasSideY, hasSideX } = this
      let { sidewidth, gap_cols_space, gap_row_space, pcs_cols_space, pcs_row_space } = this.form
      sidewidth = disabledSidewidthInput ? 0 : sidewidth || 5
      if (!hasSideY) {
        gap_cols_space = 0
        pcs_row_space = 0
      }
      if (!hasSideX) {
        gap_row_space = 0
        pcs_cols_space = 0
      }
      this.form = Object.assign(this.form, { sidewidth, gap_cols_space, gap_row_space, pcs_cols_space, pcs_row_space })
    },
    // 工艺边改变
    handleSidewidthChange(val) {
      val = Number(val)
      if (this.form.sidedirection != '无') {
        if (val < 3) {
          this.$message.warning('工艺边最小3mm')
          val = 3
        }
        if (val > 999) {
          this.$message.warning('工艺边最宽999mm')
          val = 5
        }
        if (val * 10 % 5 != 0) {
          this.$message.warning('工艺边框仅支持0.5的倍数，比如3.5, 4 , 4.5')
          val = Math.round(val * 2) / 2
        }
        this.form.sidewidth = val
      }
    },
    // 列距 行距 工艺边间距有值时，最小为2
    handleSpaceBlur(key) {
      const val = this.form[key]
      if (val !== 0 && val < 2) {
        const tipsMap = {
          pcs_cols_space: '列距',
          pcs_row_space: '行距',
          gap_cols_space: '左右工艺边间距',
          gap_row_space: '上下工艺边间距',
        }
        this.$message.warning(`${tipsMap[key] || ''}最小2mm`)
        this.form[key] = 2
      }
    },
    // 当单片尺寸改变时，自动获取推荐列表
    handleSizeChange() {
      const { width, length } = this.form
      if (width && length) {
        this.getRecommendList()
      }
    },
    // 获取推荐列表
    getRecommendList() {
      const {
        form: { width, length },
        pcbParams: { blayer, bheight }
      } = this
      if (!width || !length) {
        this.$message.error('请填写单片尺寸')
        return
      }
      const params = { source_site_type: 'PCB_UC', width, length, blayer, bheight }
      this.listLoading = true
      getBoardSets(params)
        .then(res => {
          if (!res) return
          const { status, data, msg } = res
          if (status === 1) {
            this.recommendList = data || []
          } else {
            this.$message.error(msg || '接口请求异常，请稍后再试！')
          }
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    // 使用推荐拼版
    handleUseRecommend(data) {
      let inverted = ''
      if (data.invertedx === '1' && data.invertedy === '0') {
        // X方向
        inverted = '0'
      } else if (data.invertedx === '0' && data.invertedy === '1') {
        // Y方向
        inverted = '1'
      }
      this.form = Object.assign(this.form, data, { inverted })
    },
    validateForm() {
      const { length, width, layoutx, layouty } = this.form
      const { length: setlength, width: setwidth } = this.computePcbSize
      const blayer = this.pcbParams.blayer

      const valids = [
        {
          flag: !length || !width,
          tips: '请填写单片尺寸！'
        },
        {
          flag: !layoutx || !layouty,
          tips: '请填写拼版规则！'
        },
        {
          flag: setlength < 5 || setwidth < 5,
          tips: '拼版后尺寸不能小于5*5，如有疑问请联系华秋工作人员'
        },
        {
          flag: blayer < 4 && ((setlength > 50 && setwidth > 50) || setlength > 60 || setwidth > 60),
          tips: '单双面板长和板宽不能同时超过50厘米，大小须小于 60*50，如有疑问请联系华秋工作人员'
        },
        {
          flag: blayer >= 4 && ((setlength > 40 && setwidth > 40) || setlength > 50 || setwidth > 50),
          tips: '多层板板长或板宽大小须小于 50*40，如有疑问请联系华秋工作人员'
        },
      ]

      const tipsItem = valids.find(item => (typeof item.flag === 'function' ? item.flag() : item.flag))
      if (tipsItem) {
        this.$message.warning(tipsItem.tips)
        return false
      }
      return true
    },
    // 保存
    handleSave() {
      const valids = this.validateForm()
      if (valids) {
        this.$emit('confirm', this.form)
        this.handleClose()
      }
    },
    // 取消
    handleCancel() {
      this.$emit('cancel', this.preData)
      this.handleClose()
    }
  },
}
</script>

<style lang="scss">
.board-dialog {
  .el-dialog__headerbtn:focus,
  .el-dialog__headerbtn:hover {
    .el-dialog__close {
      color: $--form-color-base;
    }
  }
  .el-dialog__body {
    padding: 20px 30px;
  }
}
</style>

<style lang="scss" scoped>
.board-dialog__title {
  font-size: 18px;
  >a {
    margin-left: 10px;
    font-size: 13px;
  }
}
.board-dialog__content {
  display: flex;
  justify-content: space-between;
  .board-dialog__form {
    margin-right: 26px;
    flex: 1;
    .board-dialog__form__unit {
      margin-left: 8px;
      color: $--color-text-secondary;
    }
    .board-dialog__form__size {
      ::v-deep .base-form-item__content {
        flex: 1;
      }
      .linker {
        margin: 0 8px;
        color: #C0C4CF;
      }
      .btn {
        margin-top: 2px;
      }
    }
    .board-dialog__form__divider {
      margin: 16px 0;
      border-bottom: 1px dashed #dcdfe6;
    }
    .board-dialog__form__inline {
      display: inline-block;
      margin-bottom: 0;
    }
    .board-dialog__form__multiLine {
      display: flex;
      .el-form-item {
        width: 50%;
        & ::v-deep .el-form-item__label {
          text-align: right;
        }
      }
      &:last-child {
        .el-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
  .board-dialog__form__radio {
    margin: 0 12px;
    & ::v-deep {
      .el-radio {
        &:first-child {
          margin-right: 12px;
        }
        .el-radio__inner {
          width: 16px;
          height: 16px;
        }
        .el-radio__input.is-checked {
          .el-radio__inner {
            border: 2px solid $--form-color-base;
            background-color: #fff;
            &::after {
              width: 8px;
              height: 8px;
              background-color: $--form-color-base;
            }
          }
          &+.el-radio__label {
            color: $--form-color-base;
          }
        } 
      }
    }
  }
  .board-dialog__form__inputNumber ::v-deep {
    .el-input__inner {
      padding-left: 8px;
      padding-right: 8px;
      text-align: left;
    }
  }
  .board-dialog__form__sidewidth ::v-deep {
    .el-input-group__prepend {
      background-color: #fff;
      .el-input__inner {
        cursor: pointer;
      }
    }
  }
  .board-dialog__content__tips {
    line-height: 1.5;
    font-size: 12px;
    color: $--color-danger;
  }
}
.board-dialog__footer {
  .el-button {
    width: 140px;
  }
  .board-dialog__footer__defaultBtn {
    margin-left: 20px;
    color: #666666;
    border-color: #D8D8D8;
    background-color: #fff;
    &:focus, &:hover {
      color: #15A675;
      border-color: #15A675;
      background-color: #fff;
    }
  }
}

</style>