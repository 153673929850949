<template>
  <div class="pressing-impedance">
    <el-table 
      border
      class="pressing-impedance__table pressing-table" 
      width="100%" 
      :data="list"
    >
      <!-- 序号 -->
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <!-- 要求阻抗 (ohm) -->
      <el-table-column prop="demand" label="要求阻抗 (ohm)" width="110"></el-table-column>
      <!-- 阻抗层 -->
      <el-table-column prop="layer" label="阻抗层"></el-table-column>
      <!-- 阻抗模式 -->
      <el-table-column prop="mode_cn" label="阻抗模式"></el-table-column>
      <!-- 原始线宽 -->
      <el-table-column prop="original_line_width" label="原始线宽"></el-table-column>
      <!-- 原始线距 -->
      <el-table-column prop="original_line_space" label="原始线距"></el-table-column>
      <!-- 原到铜间距 -->
      <el-table-column prop="original_copper_space" label="原到铜间距"></el-table-column>
      <!-- 上参考层 -->
      <el-table-column prop="top" label="上参考层"></el-table-column>
      <!-- 下参考层 -->
      <el-table-column prop="bot" label="下参考层"></el-table-column>
      <!-- 线宽、线距、到铜距离、计算结果 -->
      <el-table-column 
        v-for="item in pressingResult"
        class-name="pressing-impedance__table__bgColor"
        :key="item.prop"
        :prop="item.prop" 
        :label="item.label"
      >
        <template slot-scope="{ row, column, $index }">
          <BaseTipPopover 
            width="350px"
            :content="!row[item.prop] ? '当前无法计算出结果' : item.tips" 
            :disabled="handleColor(row, item.prop) !== 1"
          >
            <div slot="reference" :class="['default', { 1: 'red', 2: 'green' }[handleColor(row, item.prop)] || '']" >
              {{ row[item.prop] }}
            </div>
          </BaseTipPopover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      pressingResult: [ // 阻抗表 线宽、线距、到铜距离、计算结果
        { prop: 'line_width', label: '线宽', tips: '线宽小于3mil' },
        { prop: 'line_space', label: '线距', tips: '线距小于3.8mil' },
        { prop: 'copper_space', label: '到铜距离', tips: '到铜距离小于4mil' },
        { prop: 'result', label: '计算结果', tips: '计算结果与阻抗值相差±2ohm' }
      ]
    }
  },
  methods: {
    // 线宽、线距、到铜距离、计算结果背景颜色：0-默认 1-标红 2-标绿
    handleColor(row, prop) {
      const value = row[prop],
        limit = { line_width: 3, line_space: 3.8, copper_space: 4 }[prop],
        // 判断 线宽、线距、到铜距离 背景颜色是否需要标红(value === '' || value == 0  || value < limit 时标红)
        color = !value || (limit && value !== '/' && Number(value) < limit) ? 1 : row[`${prop}_color`] || 0
      return color
    },
  }
}
</script>

<style lang="scss" scoped>
.pressing-impedance {
  margin-bottom: 10px;
  .pressing-impedance__table ::v-deep {
    .el-table__header .pressing-impedance__table__bgColor {
      color: $--color-text-primary;
      background-color: #CCCFD4;
    }
    .el-table__body {
      tr:hover .default {
        background-color: #F5F7FA;
      }
      .pressing-impedance__table__bgColor {
        position: relative;
        padding: 0;
        .cell {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 0;
        }
        .default {
          padding-left: 6px;
          padding-right: 6px;
          line-height: 32px;
          height: 100%;
          background-color: #FFFFFF;
          &.red {
            color: #FFFFFF;
            background-color: $--color-primary;
          }
          &.green {
            color: #FFFFFF;
            background-color: #059A58;
          }
        }
      }
    }
  }
}
</style>