<!-- PCB文件 -->
<template>
  <BaseFormItem
    class="pcbfile"
    :label="fields.label"
  >
    <UploadAndAnalyzePcbfile 
      v-model="val"
      v-on="$listeners"
      :needAnalyeze3D="true"
      @change="handleEmit"
    />
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import UploadAndAnalyzePcbfile from '@@/components/uploadAndAnalyzePcbfile/index'

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {
    UploadAndAnalyzePcbfile
  },
  data() {
    return {
      val: this.value,
    }
  },
  computed: {
    fields() {
      return getFR4Fields('pcbfile')
    },
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>