<template>
  <div class="quote-container pcb-quote" v-loading="loading">
    <el-form 
      id="quoteForm" 
      class="quote-container__left" 
      size="small" 
      label-width="120px"
      v-anchorNav="isPcbSite ? {
        dir_basicInfo: '基本信息',
        dir_processInfo: '工艺信息',
        dir_serviceInfo: '服务信息',
      } : {}"
    >
      <!-- 基本信息 -->
      <div id="dir_basicInfo" class="quote-container__content">
        <div class="quote-container__header">
          <p>
            PCB下单<span>(硬板)</span>
          </p>
          <div>
            <BaseLink v-if="isShowMustSeeLink" href="https://www.hqpcb.com/help/c_59.html" target="_blank">
              下单必看
              <i class="el-icon-d-arrow-right" />
            </BaseLink>
          </div>
        </div>
        <!-- 下单必看提示 -->
        <MustSeeTips @hide="isShowMustSeeLink = true" />
        <!-- SMT贴片 -->
        <is_need_smt v-if="!isPcbSite" ref="is_need_smt" v-model="pcbParams.is_need_smt" :pcbParams="pcbParams" />
        <!-- PCB文件 -->
        <pcbfile 
          v-model="pcbParams.pcbfile" 
          @analyzeSuccess="handleAnalyzeFile"
          @analyze3DComplete="handleAnalyze3DComplete"
          @analyzeing3D="isLoading => iframe3DConfig.loading = isLoading"
        />
        <!-- 板子层数 -->
        <blayer v-model="pcbParams.blayer" :blind="pcbParams.blind" :initPcbParamsChange="initPcbParamsChange" />
        <!-- HDI(盲埋孔) -->
        <blind v-model="pcbParams.blind" :isHdi="isHdi" :blayer="pcbParams.blayer" :initPcbParamsChange="initPcbParamsChange" />
        <!-- 板材品牌 -->
        <board_brand 
          ref="board_brand" 
          v-model="pcbParams.board_brand" 
          :blayer="pcbParams.blayer" 
          :blind="pcbParams.blind" 
          :board_tg="pcbParams.board_tg" 
          :initPcbParamsChange="initPcbParamsChange"
        />
        <!-- TG值 -->
        <board_tg 
          v-if="isShow.board_tg" 
          v-model="pcbParams.board_tg" 
          :blayer="pcbParams.blayer" 
          :board_brand="pcbParams.board_brand" 
          :initPcbParamsChange="initPcbParamsChange"
        />
        <!-- 拼版款数 -->
        <pbnum ref="pbnum" v-model="pcbParams.pbnum" />
        <!-- 出货形式 -->
        <units 
          v-model="pcbParams.units" 
          @open="handleOpenBoardDialog"
          @reset="handleResetBoard"
        />
        <!-- 尺寸 -->
        <length 
          ref="length" 
          :length.sync="pcbParams.length" 
          :width.sync="pcbParams.width" 
          :units="pcbParams.units" 
          :disabled="isDisabledBoardParams"
        />
        <!-- 拼版规则 -->
        <layout
          v-if="isShow.layout"
          ref="layout"
          :layoutx.sync="pcbParams.layoutx"
          :layouty.sync="pcbParams.layouty"
          :exampleData="{
            setlength: computePcbSize.length,
            setwidth: computePcbSize.width,
            length: pcbParams.length,
            width: pcbParams.width,
            layouty: pcbParams.layouty,
            layoutx: pcbParams.layoutx,
            bcount: pcbParams.bcount,
            pcsNum: pcsNumForSet,
            m: pcbPrice.m
          }"
          :disabled="isDisabledBoardParams"
        />
        <!-- 数量 -->
        <bcount 
          ref="bcount"
          v-model="pcbParams.bcount" 
          :blayer="pcbParams.blayer" 
          :units="pcbParams.units" 
          :setlength="computePcbSize.length"
          :setwidth="computePcbSize.width"
          :pcsNum="pcsNumForSet" 
          :area="pcbPrice.m"
          :ladderPriceData="{
            blayer: pcbParams.blayer,
            length: pcbParams.length,
            width: pcbParams.width,
            bcount: pcbParams.bcount,
            bheight: pcbParams.bheight
          }"
          @tipsChange="tips => ladderPriceTips = tips"
        />
        <!-- 工艺边框 -->
        <sidewidth 
          ref="sidewidth" 
          v-if="isShow.sidewidth" 
          :sidedirection.sync="pcbParams.sidedirection" 
          :sidewidth.sync="pcbParams.sidewidth" 
          :disabled="isDisabledBoardParams"
        />
        <!-- 拼版后尺寸 -->
        <pcb_size
          v-if="isShow.pcb_size"
          :pcbSize="computePcbSize"
          :blayer="pcbParams.blayer"
        ></pcb_size>
      </div>

      <!-- 工艺信息 -->
      <div id="dir_processInfo" class="quote-container__content">
        <i class="quote-container__linker"></i>
        <p class="quote-container__title">工艺信息</p>
        <!-- 板子厚度-->
        <bheight
          ref="bheight"
          v-model="pcbParams.bheight"
          :blayer="pcbParams.blayer"
          :copper="pcbParams.copper"
          :insidecopper="pcbParams.insidecopper"
          :color="pcbParams.color"
          :bankong="pcbParams.bankong"
          :baobian="pcbParams.baobian"
          :area="area"
          :initPcbParamsChange="initPcbParamsChange" 
          :initDefaultPcbParamsChange="initDefaultPcbParamsChange"
        />
        <!-- 外层铜厚 -->
        <copper 
          v-model="pcbParams.copper" 
          :blayer="pcbParams.blayer" 
          :blind="pcbParams.blind" 
          :initPcbParamsChange="initPcbParamsChange"
        />
        <!-- 内层铜厚 -->
        <insidecopper 
          ref="insidecopper"
          v-if="isShow.insidecopper"
          v-model="pcbParams.insidecopper" 
          :blayer="pcbParams.blayer" 
          :blind="pcbParams.blind" 
          :copper="pcbParams.copper" 
          :bheight="pcbParams.bheight"
          :initPcbParamsChange="initPcbParamsChange" 
        />
        <!-- 最小线宽/线距 -->
        <lineweight
          v-model="pcbParams.lineweight"
          :blind="pcbParams.blind"
          :blayer="pcbParams.blayer"
          :copper="pcbParams.copper"
          :insidecopper="pcbParams.insidecopper"
          :initPcbParamsChange="initPcbParamsChange" 
        />
        <div class="quote-container__multicolumn">
          <!-- 最小孔径-->
          <vias
            v-model="pcbParams.vias"
            :blind="pcbParams.blind"
            :blayer="pcbParams.blayer"
            :bheight="pcbParams.bheight"
            :copper="pcbParams.copper"
            :insidecopper="pcbParams.insidecopper"
            :color="pcbParams.color"
            :area="area"
            :initPcbParamsChange="initPcbParamsChange" 
          />
          <!-- 孔密度 -->
          <!-- <zknum v-model="pcbParams.zknum" /> -->
        </div>
        <!-- 阻焊颜色-->
        <color
          v-model="pcbParams.color"
          :blayer="pcbParams.blayer"
          :bheight="pcbParams.bheight"
          :copper="pcbParams.copper"
          :vias="pcbParams.vias"
          :area="area"
          :initPcbParamsChange="initPcbParamsChange" 
        />
        <!-- 字符颜色 -->
        <charcolor 
          v-model="pcbParams.charcolor" 
          :color="pcbParams.color" 
          :initPcbParamsChange="initPcbParamsChange" 
        />
        <!-- 阻焊覆盖-->
        <cover
          v-model="pcbParams.cover"
          :blayer="pcbParams.blayer"
          :copper="pcbParams.copper"
          :insidecopper="pcbParams.insidecopper"
          :spray="pcbParams.spray"
          :via_in_pad="pcbParams.via_in_pad"
          :initPcbParamsChange="initPcbParamsChange" 
        />
        <!-- 盘中孔-->
        <via_in_pad
          v-model="pcbParams.via_in_pad"
          :blayer="pcbParams.blayer"
          :copper="pcbParams.copper"
          :insidecopper="pcbParams.insidecopper"
          :initPcbParamsChange="initPcbParamsChange" 
        />
        <!-- 表面处理-->
        <spray
          ref="spray"
          v-model="pcbParams.spray"
          :blayer="pcbParams.blayer"
          :bankong="pcbParams.bankong"
          :baobian="pcbParams.baobian"
          :initPcbParamsChange="initPcbParamsChange"
        />
        <!-- 沉金厚度 -->
        <cjh 
          v-if="isShow.cjh" 
          v-model="pcbParams.cjh" 
          :manufacture="manufacture" 
          :initPcbParamsChange="initPcbParamsChange"
        />
        <!-- 层压顺序 -->
        <overlay_type
          ref="overlay_type"
          v-if="isShow.overlay_type"
          :overlay_type.sync="pcbParams.overlay_type"
          :overlay_seq.sync="pcbParams.overlay_seq"
        />
        <!-- 阻抗 -->
        <impendance 
          v-model="pcbParams.impendance" 
          :copper="pcbParams.copper" 
          :pressing="pcbParams.pressing"
          :initPcbParamsChange="initPcbParamsChange"
        />
        <!-- 叠层结构 -->
        <pressing 
          ref="pressing"
          v-if="isShow.pressing" 
          v-model="pcbParams.pressing" 
          :params="{ 
            blayer: pcbParams.blayer, 
            bheight: pcbParams.bheight, 
            copper: pcbParams.copper, 
            insidecopper: pcbParams.insidecopper, 
            hdi: pcbParams.blind 
          }"
          :pressingData="pcbParams.pressing_data"
        />
        <!-- 手指斜边 -->
        <beveledge ref="beveledge" v-model="pcbParams.beveledge" :pcb-size="computePcbSize" />
        <!-- 半孔 -->
        <bankong 
          v-model="pcbParams.bankong" 
          :blayer="pcbParams.blayer"
          :initPcbParamsChange="initPcbParamsChange"  
        />
        <!-- 包边 -->
        <baobian 
          v-model="pcbParams.baobian" 
          :blayer="pcbParams.blayer"
          :initPcbParamsChange="initPcbParamsChange"  
        />
      </div>

      <!-- 服务信息 -->
      <div id="dir_serviceInfo" class="quote-container__content">
        <i class="quote-container__linker"></i>
        <p class="quote-container__title">服务信息</p>
        <!-- 测试方式 -->
        <test v-model="pcbParams.test" :testOptions="testOptions" />
        <!-- 确认生产稿 -->
        <review_file 
          ref="review_file" 
          v-model="pcbParams.review_file" 
          :blayer="pcbParams.blayer"
          :user_stamp="pcbParams.user_stamp"
          :initPcbParamsChange="initPcbParamsChange"
        />
        <!-- 产品报告 -->
        <report 
          v-model="pcbParams.report" 
          :blayer="pcbParams.blayer" 
          :impendance="pcbParams.impendance"
          :initPcbParamsChange="initPcbParamsChange" 
        />
        <!-- 报告形式 -->
        <report_type ref="report_type" v-if="isShow.report_type" v-model="pcbParams.report_type" />
        <!-- 打叉板 -->
        <cross_board v-model="pcbParams.cross_board" />
        <!-- 隔白纸 -->
        <paper ref="paper" v-model="pcbParams.paper" :length="pcbParams.length" :width="pcbParams.width" />
        <!-- 客编 -->
        <user_stamp ref="user_stamp" v-model="pcbParams.user_stamp" />
        <!-- 订单支付方式 -->
        <deduct_type 
          ref="deduct_type"
          :deduct_type.sync="pcbParams.deduct_type" 
          :deduct_limit.sync="pcbParams.deduct_limit" 
          :price-total="priceTotal.total_amount"
        />
        <!-- 品质赔付方式 -->
        <insurance_type 
          :insurance_type.sync="pcbParams.insurance_type" 
          :insurance.sync="pcbParams.insurance" 
          :pcbParams="pcbParams"
        />

        <!-- SMT贴片 -->
        <is_need_smt 
          v-if="isPcbSite" 
          ref="is_need_smt" 
          v-model="pcbParams.is_need_smt" 
          :pcbParams="pcbParams" 
          @reviewFileChange="val => pcbParams.review_file = val"
        />
      </div>

      <!-- SMT信息 -->
      <div v-if="isNeedSmt" class="quote-container__content">
        <i class="quote-container__linker"></i>
        <p class="quote-container__title">SMT信息</p>
        <SmtForm ref="smtFormRef" :data="smtParams" />
      </div>
    </el-form>

    <affix :offset="89" relativeContainerId="quoteForm">
      <Iframe3D 
        v-if="iframe3DConfig.isShow" 
        :loading="iframe3DConfig.loading"
        :analyzeId="iframe3DConfig.analyze_id"
        :analyzeKey="iframe3DConfig.key"
      />
      <QuoteRight :type="isNeedSmt ? 'PCB_SMT' : 'PCB'" />
    </affix>

    <AffixService v-if="isPcbSite" />
    <!-- 拼版工具弹框 -->
    <BoardDialog 
      ref="BoardDialogRef" 
      :pcbParams="pcbParams" 
      @confirm="handleBoardConfirm"
      @cancel="handleBoardCancel"
    />
  </div>
</template>

<script>
// 下单必看提示
import MustSeeTips from '@@/components/mustSeeTips/index'
// 右侧固钉悬浮
import Affix from '@@/components/affix/index'
// smt信息
import SmtForm from './smtForm'
// 计价页右侧
import QuoteRight from '@@/views/quoteRight/index'
// 计价页右侧 3D渲染iframe
import Iframe3D from './components/iframe3D'
// 右侧悬浮专属客服
import AffixService from './components/affixService.vue'
// 拼版工具弹框
import BoardDialog from './components/boardDialog/index'

import { isPcbSite, isLogin } from '@@/utils/is'
import { sensorsTrack } from '@@/utils/sensors'
import { debounce } from '@@/utils/utils'
import { scrollTop } from '@@/utils/pageScroll'
import { getNumberOptionKeys } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'
import { anchorNav } from '@@/utils/directives'
import { ORDER_TYPE_ENUMS } from '@@/utils/constant'
import { FR4_DEFAULT_FORM, HDI_DEFAULT_FORM, SMT_DEFAULT_FORM, FR4_BOARD_DEFAULT_FORM } from '@@/utils/defaultForm'
import {
  isShowPcbParams,
  computePcbSize,
  pcsNumForSet,
  formatPcbParams
} from '@@/utils/pcbParamsFormat'
import { formatSmtParams } from '@@/utils/smtParamsFormat'
import { getPcbQuote, getPcbPrice } from '@@/api/pcb'
import { getSmtQuote, getSmtPrice } from '@@/api/smt'

// 自动注册 fields 文件夹下的订单字段组件
const requireFields = require.context('./fields', true, /\.vue$/)
let fieldsComponents = {}
requireFields.keys().forEach(fileName => {
  let component = requireFields(fileName)
  fieldsComponents[fileName.replace(/^\.\/(.*)\.\w+$/, '$1')] = component.default
})

export default {
  components: {
    ...fieldsComponents,
    MustSeeTips,
    Affix,
    SmtForm,
    QuoteRight,
    Iframe3D,
    AffixService,
    BoardDialog
  },
  directives: {
    anchorNav
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      isShowMustSeeLink: false,
      iframe3DConfig: {
        isShow: false, // 3Diframe：解析成功-显示，解析失败-隐藏
        loading: false, // 是否正在解析中
        analyze_id: '',
        key: 0
      },
      pcbParams: { ...FR4_DEFAULT_FORM },
      smtParams: { ...SMT_DEFAULT_FORM, order_info: { ...SMT_DEFAULT_FORM.order_info }, custom_pcb_ban: 1 },
      manufacture: 1, // 外发等于0
      pcbPrice: {}, // PCB价格
      smtPrice: {}, // SMT价格
      priceTotal: {}, // 订单总计价格
      testOptions: [], // 测试方式选项
      debounceGetPirce: debounce(this.getPrice, 300), // 计价接口防抖
      initPcbParamsChange: false, // 返回修改初始化参数时，参数不进行关联变更
      initDefaultPcbParamsChange: false, // 配置的初始化默认参数变更时
      ladderPriceTips: '', // 阶梯价提示
    }
  },
  // 向右侧侧边栏注入订单参数
  provide() {
    return {
      pcbParams: this.pcbParams,
      smtParams: this.smtParams,
      getPcbPrice: () => this.pcbPrice,
      getSmtPrice: () => this.smtPrice,
      getPriceTotal: () => this.priceTotal,
      pcbValidate: valids => this.validate(valids),
      smtValidate: valids => this.smtValidate(valids)
    }
  },
  computed: {
    // PCB站点
    isPcbSite() {
      return isPcbSite
    },
    // 相关字段是否显示判断
    isShow() {
      return isShowPcbParams(this.pcbParams)
    },
    // 是否需要SMT贴片
    isNeedSmt() {
      // return this.pcbParams.is_need_smt === 1
      // PCB下单与【smt贴片】字段不再交互
      return false
    },
    // 是否是从二级导航栏点击 HDI板计价 跳转过来的
    isHdi() {
      return this.$route.query.hdi == 1
    },
    // 板子面积
    area() {
      return (this.pcbPrice.m || 0) / 10000
    },
    // 计算板子尺寸
    computePcbSize() {
      return computePcbSize(this.pcbParams)
    },
    // 连片数量换算为单片数量
    pcsNumForSet() {
      return pcsNumForSet(this.pcbParams)
    },
    // 是否禁用拼版工具相关参数
    isDisabledBoardParams() {
      // 出货形式是 连片(华秋代拼) 时，需要禁用拼版工具相关的参数
      return this.pcbParams.units == 2
    }
  },
  watch: {
    pcbParams: {
      deep: true,
      handler: function (val) {
        this.debounceGetPirce()
      }
    },
    smtParams: {
      deep: true,
      handler: function (val) {
        this.debounceGetPirce()
      }
    }
  },
  async mounted() {
    let { id, type = 'PCB' } = this.$route.query
    type = isNaN(Number(type)) ? type : ORDER_TYPE_ENUMS[type]
    if (id) {
      this.$store.dispatch('defaultValueConfig/getPcbDefaultValueConfig')
      this.pcbParams.is_need_smt = type.includes('SMT') ? 1 : 2
      this.getParams(id)
    } else {
      await this.initDefaultValueConfig()
      if (this.isHdi) {
        // 填充HDI默认参数
        this.initHdiParams()
      } else {
        this.initUrlParams()
      }
    }

    // 神策数据埋点
    sensorsTrack('PCB1_quotation_order_view', { entrance: '新版PCB计价页面浏览' })
  },
  methods: {
    // 填充配置的初始化参数
    async initDefaultValueConfig() {
      if (!isLogin()) return
      this.loading = true
      this.initDefaultPcbParamsChange = true
      await this.$store.dispatch('defaultValueConfig/getPcbDefaultValueConfig')
      const defaultValueConfig = this.$store.state.defaultValueConfig.PCB || {}
      const numberFields = getNumberOptionKeys('FR-4')
      const configData = Object.entries(defaultValueConfig).reduce((data, [key, val]) => {
        if (['none', 'default'].includes(val)) {
          data[key] = { 'none': '', 'default': FR4_DEFAULT_FORM[key] }[val]
        } else if (numberFields.includes(key)) {
          data[key] = Number(val)
        } else {
          data[key] = val
        }
        return data
      }, {})
      this.pcbParams = Object.assign(this.pcbParams, configData)
      this.loading = false
      this.$nextTick(() => {
        this.initDefaultPcbParamsChange = false
      })
    },
    // 填充HDI默认参数
    initHdiParams() {
      this.initPcbParamsChange = true
      this.pcbParams = Object.assign(this.pcbParams, HDI_DEFAULT_FORM)
      this.$nextTick(() => {
        this.initPcbParamsChange = false
      })
    },
    // 填充url参数
    initUrlParams() {
      const routeQuery = this.$route.query
      // 需要添加 b 前缀的key
      const otherUrlParamKeys = ['count', 'layer', 'height', 'lind']
      const urlParamKeys = ['length', 'width', 'blayer', 'bcount', 'bheight', 'lineweight', ...otherUrlParamKeys]

      if (!urlParamKeys.some(key => routeQuery[key])) return

      // 当url有带参数时，填充参数
      this.initPcbParamsChange = true
      const urlData = urlParamKeys.reduce((data, key) => {
        const val = routeQuery[key]
        const paramKey = otherUrlParamKeys.includes(key) ? `b${key}` : key
        data[paramKey] = val ? +val : FR4_DEFAULT_FORM[paramKey]
        if (paramKey === 'blayer') {
          let board_tg
          if (val == 4) {
            board_tg = 'TG130'
          } else if ([6, 8].includes(+val)) {
            board_tg = 'TG150'
          } else if (val >= 4) {
            board_tg = 'TG170'
          }
          data.board_tg = board_tg || FR4_DEFAULT_FORM.board_tg
        }
        return data
      }, {})
      this.pcbParams = Object.assign(this.pcbParams, urlData)
      this.$nextTick(() => {
        this.initPcbParamsChange = false
      })
    },
    // 文件解析成功
    handleAnalyzeFile(data) {
      const { file_type, blength, bwidth } = data || {}
      // 解析成功后，需要先重置拼版工具相关的字段
      this.pcbParams = Object.assign(
        this.pcbParams, 
        FR4_BOARD_DEFAULT_FORM, 
        data, 
        (file_type === -1 ? {} : { length: blength, width: bwidth })
      )
    },
    // 3D数据解析成功
    handleAnalyze3DComplete(data = {}) {
      this.iframe3DConfig = Object.assign(this.iframe3DConfig, data)
    },

    // 需要转为 Number 类型的字段
    transformNumberFields(type, params) {
      const fields = getNumberOptionKeys(type)
      return fields.reduce((data, key) => {
        if (params[key] !== undefined) {
          data[key] = Number(params[key])
        }
        return data
      }, {})
    },
    // PCB参数处理
    initPcbParams(params) {
      this.initPcbParamsChange = true
      const transformNumberFields = this.transformNumberFields('FR-4', params)
      const { report, pressing, pressing_computer_id, pressing_data } = params
      this.pcbParams = Object.assign(this.pcbParams, params, transformNumberFields, {
        // 产品报告
        report: report ? report.split(',') : ['无需'],
        // 叠层结构：当有计算id时，前端拼上计算id，用于区分接口返回的选项
        pressing: pressing_computer_id ? `${pressing_computer_id}__${pressing}` : pressing,
        // 叠层结构计算选项查看数据
        pressing_data: pressing_computer_id ? pressing_data : null
      })
      this.$nextTick(() => {
        this.initPcbParamsChange = false
      })
    },
    // SMT参数处理
    initSmtParams(params) {
      const { smt_params, order_info = {} } = params
      const transformNumberFields = this.transformNumberFields('SMT', smt_params)
      this.smtParams = Object.assign(this.smtParams, smt_params, transformNumberFields, { order_info: {...order_info} })
    },
    // 获取订单信息
    async getParams(id) {
      try {
        this.loading = true
        this.isNeedSmt ? await this.getSmtParams(id) : await this.getPcbParams(id)
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    // 获取PCB订单信息
    async getPcbParams(quoteid) {
      //  kicad插件参数
      const guid = this.$route.query.guid
      return await getPcbQuote({ quoteid, guid })
        .then(res => {
          if (!res) return false
          const { retCode, retMsg, result } = res
          if (retCode === 0) {
            const { quote, extend, notify } = result
            const params = { ...extend, ...notify, ...quote, quoteid }
            this.initPcbParams(params)
          } else {
            this.$message.error(retMsg)
          }
        })
    },
    // 获取SMT订单信息
    async getSmtParams(smtTmpId) {
      return await getSmtQuote({ smtTmpId })
        .then(res => {
          if (!res) return false
          const { suc, body, msg } = res
          if (suc) {
            const { pcb_params, ...smt_params } = body
            // PCB参数处理
            this.initPcbParams(pcb_params)
            // SMT参数处理
            this.initSmtParams(smt_params)
          } else {
            this.$message.error(msg)
          }
        })
    },

    // 计价
    async getPrice() {
      const { length, width, bcount } = this.pcbParams
      // 尺寸、数量没填时不能计价
      if (!length || !width || !bcount) return
      // 需要SMT贴片时，调用SMT计价接口 否则调用PCB计价接口
      const res = this.isNeedSmt ? await this.getSmtPrice() : await this.getPcbPrice()

      const { detail = {} } = res
      const { pcb = {}, smt = {} } = detail
      const { suggest = {}, deltime, test, manufacture } = pcb

      this.pcbPrice = pcb
      this.smtPrice = smt
      this.priceTotal = res
      // 插入阶梯价提示
      this.priceTotal.ladderPriceTips = this.ladderPriceTips

      // 是否时外发
      this.manufacture = manufacture
      // 外发沉金厚度只能为1
      if (manufacture == 0 && this.pcbParams.spray === '沉金' && this.pcbParams.cjh != 1) {
        this.pcbParams.cjh = 1
        alertFieldsTips('沉金厚度')
      }

      // 测试方式选项
      this.testOptions = suggest.test
      this.pcbParams.test = test

      // 生产周期
      this.pcbParams.deltime = deltime
    },
    // PCB 计价
    async getPcbPrice() {
      // want_bonus_activity：获取优惠券列表
      const params = formatPcbParams(this.pcbParams, { want_bonus_activity: 1 })
      return await getPcbPrice(params)
        .then(res => {
          if (!res) return {}
          const { retCode, result } = res
          return retCode === 0 ? result : {}
        })
    },
    // SMT + PCB 计价
    async getSmtPrice() {
      const { pcbParams, smtParams } = this
      return await getSmtPrice({ pcb_params: formatPcbParams(pcbParams), ...formatSmtParams(smtParams) })
        .then(res => {
          if (!res) return {}
          const { suc, body } = res
          return suc ? body : {}
        })
    },

    scrollIntoView(key) {
      if (this.$refs[key]) {
        // 滚动到元素上面偏移50px的位置
        const top = this.$refs[key].$el.getBoundingClientRect().top
        scrollTop(top, -50)
      }
    },
    validate(data) {
      data.forEach((item, index) => {
        const ref = this.$refs[item.key]
        if (ref && ref.isShowError) {
          // 显示错误提示样式
          ref.isShowError(true, index === 0)
        }
      })
      const fristItem = data[0]
      this.$message.closeAll()
      this.$message({
        type: 'error',
        offset: 400,
        message: fristItem.tips
      })
      this.scrollIntoView(fristItem.key)
    },
    smtValidate(data) {
      this.$nextTick(() => {
        this.$refs.smtFormRef && this.$refs.smtFormRef.validate(data)
      })
    },

    // 显示拼版工具弹框
    handleOpenBoardDialog(preUnits) {
      this.$refs.BoardDialogRef.open(preUnits ? { units: preUnits } : {})
    },
    // 拼版工具弹框保存
    handleBoardConfirm(data) {
      this.pcbParams = Object.assign(this.pcbParams, { ...data, units: 2 })
    },
    // 拼版工具弹框取消
    handleBoardCancel(preData) {
      this.pcbParams = Object.assign(this.pcbParams, preData || {})
    },
    // 重置拼版工具相关参数
    handleResetBoard() {
      this.pcbParams = Object.assign(this.pcbParams, FR4_BOARD_DEFAULT_FORM)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@@/styles/quote-container.scss';
</style>
<style lang="scss">
@import '~@@/styles/directives.scss';
</style>
