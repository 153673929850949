<!-- 出货形式 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleChange">
      <BaseTipPopover 
        v-for="item in fields.options"
        :key="item.value" 
        :content="item.tips" 
        :disabled="!item.tips"
      >
        <el-radio-button
          slot="reference"
          :label="item.value"
        >{{ item.label }}</el-radio-button>
      </BaseTipPopover>
    </BaseRadioGroup>

    <BaseTextButton 
      slot="other"
      v-if="val == 2"
      @click="handleOpenBoardDialog(false)"
    >修改拼版</BaseTextButton>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      val: this.value,
      preVal: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('units')
    }
  },
  watch: {
    value(newVal, oldVal) {
      this.val = newVal
    },
    val(newVal, oldVal) {
      this.preVal = oldVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    },
    handleChange() {
      if (this.val == 2) {
        this.handleOpenBoardDialog(this.preVal)
      } else if (this.preVal == 2) {
        // 从连片(华秋代拼)切换到别的选项时，弹框提示
        this.$alert('修改出货形式参数会影响拼版参数，会清空当前拼版参数，请确认是否继续修改？', '温馨提示', {
          center: true,
          lockScroll: false,
          showCancelButton: true,
          closeOnPressEscape: true,
          confirmButtonText: '确认',
          customClass: 'alert-fields-tips',
          callback: action => {
            // 取消
            if (action === 'cancel') {
              this.val = 2
              this.handleEmit()
            } else {
              // 确认
              this.$emit('reset')
            }
          }
        })
      }
      this.handleEmit()
    },
    handleOpenBoardDialog(preVal) {
      this.$emit('open', preVal)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>