<!-- 单片数量 -->
<template>
  <div>
    <BaseFormItem
      class="bcount"  
      isShowRequiredIcon
      :unit="unit"
      :label="label"
      :labelTips="fields.labelTips"
      :fillTips="fillTips"
      :isError="isError"
      :isErrorBlink="isErrorBlink"
    >
      <BaseSelect 
        ref="bcountSelect"
        v-model="val" 
        popper-class="bcount__select" 
        width="191px"
        placeholder="请选择"
        @change="handleEmit"
      >
        <el-option
          v-for="item in fields.options"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
        <div class="bcount__select__other">
          <p>
            其它数量：
            <span v-show="areaLimit > 0">(数量x长x宽需大于{{areaLimit}}㎡)</span>
          </p>
          <div>
            <BaseInput 
              v-model="otherVal" 
              size="mini" 
              width="150px"
              @keyup.enter.native="handleUseOther"
            ></BaseInput>
            <BaseButton size="mini" @click="handleUseOther">确定</BaseButton>
          </div>
        </div>
      </BaseSelect>

      <div slot="other">
        <template v-if="units == 2 && pcsNum">
          <span>=</span>
          <span class="bcount__pcsnum">{{ pcsNum }}</span>
          <span>Pcs</span>
        </template>

        <span v-if="area" class="bcount__area">
          （订单面积 {{(area / 10000).toFixed(4)}}㎡）
        </span>

        <BaseTextButton v-if="isShowLadderPriceBtn" @click="handleShowLadderPrice">
          浮动阶梯价
          <i v-if="ladderPrice.isShow" class="el-icon-arrow-up"></i>
          <i v-else class="el-icon-arrow-down"></i>
        </BaseTextButton>
      </div>
    </BaseFormItem>
    <!-- 阶梯价格表 -->
    <LadderPrice 
      v-show="ladderPrice.isShow" 
      :ladderPriceData="ladderPriceData" 
      @tipsChange="tips => $emit('tipsChange', tips)"
      @change="list => ladderPrice.list = list"
    />

  </div>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import LadderPrice from '../components/ladderPrice'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 出货形式
    units: {
      type: Number,
      default: 1
    },
    setwidth: {
      type: [Number, String],
      default: ''
    },
    setlength: {
      type: [Number, String],
      default: ''
    },
    // 单片数量
    pcsNum: {
      type: Number,
      default: 0
    },
    // 板子面积
    area: {
      type: [Number, String],
      default: ''
    },
    // 获取阶梯价参数
    ladderPriceData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    LadderPrice
  },
  data() {
    return {
      val: this.value,
      otherVal: '',
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
      ladderPrice: {
        isShow: false, // 是否显示阶梯价表格
        prevShowStatus: true, // 上一次的显示状态
        list: [] // 阶梯价数据
      }
    }
  },
  computed: {
    fields() {
      return getFR4Fields('bcount')
    },
    unit() {
      return this.units == 1 ? 'Pcs':'Set'
    },
    label() {
      return this.units == 1 ? '单片数量':'连片数量'
    },
    fillTips() {
      return !this.val ? '请选择' : ''
    },
    areaLimit() {
      return { 1: 5, 2: 5, 4: 5, 6: 3 }[this.blayer] || 0
    },
    // 是否显示浮动阶梯价按钮
    isShowLadderPriceBtn() {
      // 订单为1-4层板，且面积大于 0.5 平米
      const isShow = ([1, 2, 4].includes(+this.blayer) && ((this.area || 0) / 10000) > 0.5) && this.ladderPrice.list.length > 0
      if (!isShow) {
        this.ladderPrice.isShow = false
      } else {
        this.ladderPrice.isShow = this.ladderPrice.prevShowStatus
      }
      return isShow
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      this.$emit('input', this.val)
      this.$emit('change', this.val)
      this.isShowError(false)
    },
    handleUseOther() {
      const { otherVal, areaLimit, setwidth, setlength } = this
      if (otherVal > 0) {
        const minBcount = parseInt(areaLimit * 10000 / (setwidth * setlength))
        if (otherVal < minBcount) {
          this.$message.warning(`最少${minBcount}片才大于${areaLimit}平米，且数量需为5的倍数`)
        } else if (otherVal % 5 != 0) {
          this.$message.warning('输入数量需为5的倍数')
        } else {
          this.val = +otherVal
          this.otherVal = ''
          this.handleEmit()
          this.$refs.bcountSelect.getRefs().blur()
        }
      } else {
        this.$message.warning('请输入其它数量')
      }
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    },
    // 切换浮动阶梯价表格显示/隐藏
    handleShowLadderPrice() {
      const isShow = !this.ladderPrice.isShow
      this.ladderPrice.prevShowStatus = isShow
      this.ladderPrice.isShow = isShow
    }
  }
}
</script>

<style lang="scss">
.bcount {
  .bcount__pcsnum {
    margin: 0 4px;
    color: $--color-primary;
  }
  .bcount__area {
    color: $--color-text-placeholder;
  }
}
</style>
<style lang="scss">
.bcount__select {
  .el-select-dropdown__wrap {
    min-height: 300px;
  }
  .el-select-dropdown__list {
    display: flex;
    flex-wrap: wrap;
    padding: 6px;
    width: 300px;
    .el-select-dropdown__item {
      padding: 0 2px 0 7px;
      width: 20%;
      height: 26px;
      line-height: 26px;
    }
  }
  .bcount__select__other {
    padding: 0 7px;
    >p {
      margin-bottom: 6px;
      color: $--color-text-secondary;
      >span {
        color: $--color-text-placeholder;
      }
    }
    >div {
      .el-button {
        margin-left: 4px;
      }
    }
  }
}
</style>