<!-- 板子层数 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
    :valueConfig="{ key: 'blayer', hideNoneOption: true }"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: [String, Number],
      default: 2
    },
    // HDI(盲埋孔)
    blind: {
      type: Number,
      default: 0
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: +this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('blayer')
    },
    options() {
      const { blind, fields } = this
      let options = [...fields.options]
      // 有 HDI(盲埋孔) 时 不能选择 1 2 层
      if (blind > 0) {
        options = options.map(item => ({...item, disabled: item.value < 4}))
      }

      return options
    }
  },
  watch: {
    value(newVal) {
      this.val = +newVal
    },
    blind(blind) {
      if (blind > 0 && this.val < 4) {
        this.changeVal(4)
      }
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>