<!-- 阻抗 -->
<template>
  <BaseFormItem
    class="impendance"
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <span v-if="val == 1" slot="other" class="impendance__tips">
      华秋提供免费的试算阻抗及调整线宽服务，阻抗按±10%（＜50Ω±5Ω）管控。
    </span>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    // 外层铜厚
    copper: {
      type: Number,
      default: 1
    },
    // 叠层结构
    pressing: {
      type: String,
      default: ''
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('impendance')
    },
    // 当叠层结构是计算的选项时，禁用 无 选项，且选中 有 选项
    options() {
      const { fields, pressing } = this
      const isDisabledOption0 = pressing.includes('__')
      if (isDisabledOption0 && this.val != 1) {
        this.changeVal(1)
      }
      return fields.options.map(item => ({ ...item, disabled: item.value === 0 ? isDisabledOption0 : false }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    copper(copper) {
      // 外层铜厚等于2时，阻抗为 无
      if (copper == 2 && this.val !== 0) {
        this.changeVal(0)
      }
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
.impendance {
  .impendance__tips {
    font-size: 12px;
    color: $--color-text-placeholder;
  }
}
</style>