// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@@/assets/img/icons/icon_anchor_nav.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".el-message-box .el-message-box__btns .el-button.el-button--default:focus,.el-message-box .el-message-box__btns .el-button.el-button--default:hover{color:#15a675;background:#fff;border-color:#15a675}.el-message-box .el-message-box__btns .el-button.el-button--default.el-button--primary,.el-message-box .el-message-box__btns .el-button.el-button--primary{color:#fff;background:#b60005;border-color:#b60005}.anchor-nav-directives{position:fixed;top:160px}.anchor-nav-directives:before{content:\"\";position:absolute;left:0;top:0;bottom:0;display:block;width:1px;height:100%;background:linear-gradient(180deg,#f5f5f5,#e0e0e0 17%,#e0e0e0 53%,#e0e0e0 83%,#f5f5f5)}.anchor-nav-directives li{display:flex;justify-content:center;align-items:center;flex-wrap:wrap;box-sizing:border-box;width:76px;height:32px;text-align:center;cursor:pointer}.anchor-nav-directives li+li{margin-top:4px}.anchor-nav-directives li:not(.active):hover{background:#fdfdfd;border-radius:2px 5px 5px 2px}.anchor-nav-directives li.active{position:relative;font-weight:700;color:#15a675}.anchor-nav-directives li.active:before{content:\"\";position:absolute;left:-5px;display:block;width:11px;height:14px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}", ""]);
// Exports
module.exports = exports;
