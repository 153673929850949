<!-- 测试方式 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: String,
      default: '样品免费测试'
    },
    testOptions: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('test')
    },
    options() {
      const { fields, testOptions } = this
      if (testOptions && testOptions.length > 0) {
        return testOptions.map(({ real, word }) => ({ label: word, value: real }))
      }
      
      return fields.options
    }
  },
  watch: {
    value(newVal) {
      if (this.val != newVal && this.val == '抽测') { 
        this.changeVal(newVal)
      }
      this.val = newVal
    },
    testOptions(testOptions) {
      this.testOptions = testOptions
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    },
    changeVal(val) {
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss">
</style>