<!-- 拼版后尺寸 -->
<template>
  <BaseFormItem label="拼版后尺寸" class="pcb-size">
    {{ pcbSize.length || 0 }} x {{ pcbSize.width || 0 }} cm
  </BaseFormItem>
</template>

<script>
export default {
  props: {
    pcbSize: {
      type: Object,
      default: () => ({})
    },
    blayer: {
      type: [String, Number],
      default: 2
    },
  }
}
</script>

<style lang="scss" scoped>
</style>