<template>
  <div class="pressing-params">
    <div class="pressing-params__btns">
      <div>
        <!-- 添加 -->
        <el-input size="mini" v-model="addRows" placeholder="行数">
          <template slot="append">
            <el-button size="mini" @click="handleAdd">添加</el-button>
          </template>
        </el-input>
        <!-- 删除 -->
        <el-button size="mini" :disabled="disabledDelBtn" @click="handleDel">删除</el-button>
        <!-- 复制 -->
        <el-button size="mini" :disabled="disabledCopyBtn" @click="handleCopy">复制</el-button>
        <!-- 计算 -->
        <el-button size="mini" type="primary" @click="handleValidate">计算</el-button>
      </div>
      <p>
        <BaseTipPopover 
          width="810px"
          :content="`
            1、请输入您需要的阻抗值及线宽线距，系统将根据您的要求匹配出合适的叠层结构，其中线宽间距可能会有调整：\n
            * 若匹配结果的线宽、线距、到铜距离的计算值标红，有可能超出我司制程能力；\n
            * 若匹配结果的阻抗值计算结果标红，是与您要求的阻抗值相差±2ohm\n
            2、目前我司可匹配4和6层板的叠层结构
          `" 
        ></BaseTipPopover>
      </p>
    </div>
    <!-- 选择叠层结构表格 -->
    <el-table class="pressing-params__list pressing-table" border :data="params">
      <!-- 选择 -->
      <el-table-column label="选择">
        <template slot-scope="{ row, $index }">
          <el-radio 
            :label="$index"
            v-model="currentParamsIndex"
          ></el-radio>
        </template>
      </el-table-column>
      <!-- 要求阻抗（ohm） -->
      <el-table-column label="要求阻抗（ohm）">
        <template slot-scope="{ row, $index }">
          <el-input-number 
            size="mini"
            placeholder="请输入"
            :precision="0" 
            :min="40" 
            :max="130" 
            :controls="false" 
            :class="{ 'error': emptyParams[$index] && emptyParams[$index].includes('demand') }"
            v-model="params[$index].demand"
          ></el-input-number>
        </template>
      </el-table-column>
      <!-- 阻抗层 -->
      <el-table-column label="阻抗层">
        <template slot-scope="{ row, $index }">
          <el-select 
            size="mini"
            popper-class="pressing-select" 
            placeholder="请选择"
            v-model="params[$index].layer"
            @change="val => params[$index] = Object.assign(params[$index], { top: '', bot: '' })" 
          >
            <el-option 
              v-for="item in blayerOptions" 
              :key="item" 
              :value="item" 
              :label="item" 
            />
          </el-select>
        </template>
      </el-table-column>
      <!-- 阻抗模式 -->
      <el-table-column label="阻抗模式">
        <template slot-scope="{ row, $index }">
          <el-select 
            size="mini"
            popper-class="pressing-select" 
            placeholder="请选择"
            v-model="params[$index].mode"
            @change="() => handleModeChange(params[$index])"
          >
            <el-option 
              v-for="(label, value) in modeOptions"
              :key="value"  
              :value="Number(value)" 
              :label="label" 
            />
          </el-select>
        </template>
      </el-table-column>
      <!-- 上参考层 -->
      <el-table-column label="上参考层">
        <template slot-scope="{ row, $index }">
          <div v-if="params[$index].top === '/'">/</div>
          <el-select 
            v-else
            size="mini"
            popper-class="pressing-select" 
            placeholder="请选择"
            v-model="params[$index].top"
          >
            <el-option 
              v-for="item in topOptions(row, $index)" 
              :key="item"
              :value="item" 
              :label="item" 
            />
          </el-select>
        </template>
      </el-table-column>
      <!-- 下参考层 -->
      <el-table-column label="下参考层">
        <template slot-scope="{ row, $index }">
          <div v-if="params[$index].bot === '/'">/</div>
          <el-select 
            v-else
            size="mini"
            popper-class="pressing-select" 
            placeholder="请选择"
            v-model="params[$index].bot"
          >
            <el-option 
              v-for="item in botOptions(row, $index)" 
              :key="item"
              :value="item" 
              :label="item" 
            />
          </el-select>
        </template>
      </el-table-column>
      <!-- 原始线宽（mil） -->
      <el-table-column label="原始线宽（mil）">
        <template slot-scope="{ row, $index }">
          <el-input-number 
            size="mini"
            placeholder="请输入"
            :precision="2" 
            :min="3" 
            :max="80" 
            :controls="false" 
            :class="{ 'error': emptyParams[$index] && emptyParams[$index].includes('original_line_width') }"
            v-model="params[$index].original_line_width"
          ></el-input-number>
        </template>
      </el-table-column>
      <!-- 原始线距（mil） -->
      <el-table-column label="原始线距（mil）">
        <template slot-scope="{ row, $index }">
          <div v-if="![1, 3].includes(params[$index].mode)">/</div>
          <el-input-number 
            v-else
            size="mini"
            placeholder="请输入"
            :precision="2" 
            :min="3.8" 
            :max="20" 
            :controls="false" 
            :class="{ 'error': emptyParams[$index] && emptyParams[$index].includes('original_line_space') }"
            v-model="params[$index].original_line_space"
          ></el-input-number>
        </template>
      </el-table-column>
      <!-- 原到铜距离（mil） -->
      <el-table-column label="原到铜距离（mil）">
        <template slot-scope="{ row, $index }">
          <div v-if="![2, 3].includes(params[$index].mode)">/</div>
          <el-input-number 
            v-else
            size='mini'
            placeholder="请输入"
            :precision="2" 
            :min="4" 
            :max="50" 
            :controls="false" 
            :class="{ 'error': emptyParams[$index] && emptyParams[$index].includes('original_copper_space') }"
            v-model="params[$index].original_copper_space"
          ></el-input-number>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

// 推荐结构计算默认参数
const PARAMS_ITEM = {
  demand: undefined, // 要求阻抗（ohm）
  layer: 'L1', // 阻抗层
  mode: 0, // 阻抗模式 0-单端 1-差分 2-共面单端 3-共面差分
  top: '', // 上参考层
  bot: '', // 下参考层
  original_line_width: undefined, // 原始线宽
  original_line_space: '/', // 原始线距
  original_copper_space: '/', // 原始到铜距离
}

export default {
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      addRows: '', // 要添加的参数行数
      currentParamsIndex: '', // 推荐结构参数当前选中的索引
      params: [{...PARAMS_ITEM}], // 推荐结构参数
      emptyParams: [], // 计算时校验为空的字段
      modeOptions: { 0: '单端', 1: '差分', 2: '共面单端', 3: '共面差分' }, // 阻抗模式选项
    }
  },
  computed: {
    // 禁用删除按钮
    disabledDelBtn() {
      const { currentParamsIndex, params } = this
      return (!currentParamsIndex && currentParamsIndex !== 0) || params.length < 2
    },
    // 禁用复制按钮
    disabledCopyBtn() {
      const { currentParamsIndex } = this
      return !currentParamsIndex && currentParamsIndex !== 0
    },
    // 阻抗层选项：[L1, L2, ..., L(当前板子层数)]
    blayerOptions() {
      const blayer = this.order.blayer
      return Array.from({length: blayer}, (x, index) => `L${index + 1}`)
    },
  },
  watch: {
    params: {
      handler: function (newVal, oldVal) {
        this.$nextTick(() => {
          this.$emit('reset')
        })
      },
      deep: true
    }
  },
  methods: {
    // 重置
    handleReset() {
      this.addRows = ''
      this.currentParamsIndex = ''
      this.params = [{...PARAMS_ITEM}]
    },
    // 添加
    handleAdd() {
      const rows = Number(this.addRows)
      // 一次最多添加50条
      this.addRows = isNaN(rows) || rows <= 0 ? 1 : Math.min(rows, 50)
      const params = Array.from({ length: this.addRows }).map(() => ({...PARAMS_ITEM}))
      this.params.push(...params)
    },
    // 删除
    handleDel() {
      const index = this.currentParamsIndex
      if (!index && index !== 0) return
      this.params.splice(index, 1)
      this.currentParamsIndex = ''
    },
    // 复制
    handleCopy() {
      const index = this.currentParamsIndex
      if (!index && index !== 0) return
      this.params.push(Object.assign({}, this.params[index]))
    },
    // 对计算字段进行校验
    handleValidate() {
      // 需要对 要求阻抗（ohm）、线宽、线距、到铜距离 做必填校验
      const NOT_EMPTY_FIELDS = ['demand', 'original_line_width', 'original_line_space', 'original_copper_space']
      const emptyParams = []
      this.params.forEach(item => {
        emptyParams.push(NOT_EMPTY_FIELDS.filter(key => !item[key] && item[key] != 0))
      })
      this.emptyParams = emptyParams
      if (emptyParams.some(item => item.length > 0)) return

      this.$emit('compute', this.params)
    },

    // 上参考层选项
    topOptions(row, $index) {
      const blayerOptions = this.blayerOptions
      const { top, layer } = row
      const optionIndex = blayerOptions.findIndex(i => i === layer)
      // 上参考层选项：[L1, L2, ..., (当前阻抗层)]
      const options = blayerOptions.filter((i, index) => index < optionIndex)
      // 当阻抗层改变时，上参考层默认为阻抗层上一层，当上参考层改变时，为选中的值
      this.params[$index].top = options.length > 0 
        ? top && options.includes(top) ? top : options[optionIndex - 1]
        : '/'

      return options
    },
    // 下参考层选项
    botOptions(row, $index) {
      const blayerOptions = this.blayerOptions
      const { bot, layer } = row
      const optionIndex = blayerOptions.findIndex(i => i === layer)
      // 下参考层：[(当前阻抗层), ..., (当前板子层数)]
      const options = blayerOptions.filter((i, index) => index > optionIndex)
      // 当阻抗层改变时，下参考层默认为阻抗层下一层，当下参考层改变时，为选中的值
      this.params[$index].bot = options.length > 0 
        ? bot && options.includes(bot) ? bot : options[0] 
        : '/'

      return options
    },
    // 阻抗模式切换
    handleModeChange(paramsItem) {
      const { mode, original_line_space, original_copper_space } = paramsItem

      // 当阻抗模式为差分、共面差分时可输入原始线距
      paramsItem.original_line_space = [1, 3].includes(mode) 
        ? original_line_space && original_line_space !== '/' ? original_line_space : undefined
        : '/'
      
      // 当阻抗模式为共面单端、共面差分时可输入原到铜距离
      paramsItem.original_copper_space = [2, 3].includes(mode) 
        ? original_copper_space && original_copper_space !== '/' ? original_copper_space : undefined
        : '/'
    }
  },
}
</script>

<style lang="scss" scoped>
.pressing-params {
  .pressing-params__btns {
    display: flex;
    align-items: center;
    margin: 20px 0;
    & ::v-deep {
      .el-input-group {
        margin-right: 10px;
        width: 116px;
        &.el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: $--color-primary;
        }
      }
      .el-input-group__append {
        padding: 0 15px;
        .el-button:hover {
          color: unset;
          border-color: transparent;
          background-color: transparent;
        }
      }
    }
    >p {
      margin-left: 10px;
    }
  }
  // 叠层结构列表
  .pressing-params__list ::v-deep {
    .el-input,
    .el-select {
      .el-input__inner {
        border: 0;
      }
    }

    .el-input-number {
      width: 100%;
      .el-input__inner {
        text-align: left;
      }
      &.error {
        .el-input__inner {
          border: 1px solid $--color-danger;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.pressing-select {
  .el-select-dropdown__item.selected {
    color: $--color-primary;
  }
}
</style>