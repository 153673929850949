<!-- 阻焊颜色 -->
<template>
  <BaseFormItem
    class="color"
    :label="fields.label"
    :labelTips="fields.labelTips"
    valueConfig="color"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
        :style="colors[item.value]"
      >
        <ColorBlock :color="item.value" />
        {{ item.label }}
      </el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import ColorBlock from '@@/components/colorBlock/index'

import { COLORS } from '@@/utils/constant'
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: String,
      default: '绿色'
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 板子厚度
    bheight: {
      type: [Number, String]
    },
    // 外层铜厚
    copper: {
      type: Number,
      default: 1
    },
    // 最小孔径
    vias: {
      ttype: Number,
      default: 0.3
    },
    // 面积
    area: {
      type: Number,
      default: 0
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ColorBlock
  },
  data() {
    return {
      val: this.value,
      colors: COLORS
    }
  },
  computed: {
    fields() {
      return getFR4Fields('color')
    },
    options() {
      const { val, fields, blayer, bheight, copper, vias, area } = this
      const otherOptions = ['哑光绿油', '哑光蓝油']
      let options = fields.options.filter(item => !otherOptions.includes(item.value))
      // 当值为 哑光绿油 || '哑光蓝油' 时 才显示对应的选项
      if (otherOptions.includes(val)) {
        options.push(...fields.options.filter(item => item.value === val))
      }
      let disabledOptions = []
      if ([1, 2].includes(blayer) && area <= 3) {
        if ((copper < 3 && [0.6, 0.8, 2].includes(Number(bheight))) || (copper == 2 && [1, 1.2, 1.6].includes(Number(bheight)))) {
          disabledOptions = ['黑色']
        }
        if ((copper == 2 && bheight <= 2.0) || (copper == 1 && vias < 0.25 && bheight != 1.6)) {
          disabledOptions = ['蓝色', '红色', '黄色', '黑色', '哑光黑油', '白色']
        }
        if (copper == 1 && vias < 0.25 && bheight == 1.6) {
          disabledOptions = ['黄色']
        }
      }

      // 如果当前项被禁用，选中第一个可选的选项
      if (disabledOptions.includes(val)) {
        this.changeVal(options.find(item => !disabledOptions.includes(item.value)).value)
      }
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    area(area) {
      if (this.initPcbParamsChange) return
      const { val, blayer, bheight, copper } = this
      if ([1, 2].includes(blayer) && area <= 3) {
        if (
          (val == '黑色' && [0.6, 0.8, 2].includes(Number(bheight))) ||
          (val == '黑色' && [1, 1.2, 1.6].includes(Number(bheight)) && copper == 2) ||
          (val != '绿色' && bheight >= 0.6 && bheight <= 2.0 && copper == 2)
        ) {
          this.val = '绿色'
        }
      }
      this.handleEmit()
    },
    blayer(blayer) {
      if (this.initPcbParamsChange) return
      const { val, area, bheight, copper } = this
      if ([1, 2].includes(blayer) && area <= 3 && val == '黑色') {
        if (
          ([0.6, 0.8, 2].includes(Number(bheight))) ||
          ([1, 1.2, 1.6].includes(Number(bheight)) && copper == 2)
        ) {
          this.val = '绿色'
        }
      }
      this.handleEmit()
    },
    copper(copper) {
      const { val, area, blayer, bheight } = this
      if ([1, 2].includes(blayer) && area <= 3) {
        if (
          (copper == 2 && val != '绿色' && bheight >= 0.6 && bheight <= 2.0) ||
          (val == '黑色' && [1, 1.2, 1.6].includes(Number(bheight)) && copper == 2)
        ) {
          this.changeVal('绿色')
        }
      }
    },
    bheight(bheight) {
      const { val, area, blayer, copper } = this
      if ([1, 2].includes(blayer) && area <= 3 && val == '黑色') {
        if (
          ([0.6, 0.8, 2].includes(Number(bheight))) ||
          ([1, 1.2, 1.6].includes(Number(bheight)) && copper == 2)
        ) {
          this.changeVal('绿色')
        }
      }
    },
    vias(vias) {
      const { val, area, blayer, copper, bheight } = this
      if ([1, 2].includes(blayer) && area <= 3 && val != '绿色' && vias < 0.25 && bheight != 1.6 && copper == 1) {
        this.changeVal('绿色')
      }
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
.color {
  .base-radio-group ::v-deep .el-radio-button {
    // 选中状态
    $--radio-checked-font-color: var(--color);
    $--radio-checked-border-color: var(--border-color);
    $--radio-checked-background-color: var(--background);
    // hover状态
    $--radio-hover-font-color: var(--color);
    $--radio-hover-border-color: var(--border-color);
    .el-radio-button__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    // 选中
    &.is-active {
      .el-radio-button__inner {
        color: $--radio-checked-font-color;
        border-color: $--radio-checked-border-color;
        background: $--radio-checked-background-color;
        box-shadow: none;
        // box-shadow: 0 0 3px $--radio-border-color;
      }
    }
    // hover
    &:not(.is-disabled):not(.is-active):hover {
      .el-radio-button__inner {
        color: $--radio-hover-font-color;
        border-color: $--radio-hover-border-color;
        background: $--radio-hover-background-color;
      }
    }
    // 左边框
    &:not(:first-child) {
      border-left: 1px solid transparent;
      margin-left: -1px;
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
      &.is-active:not(.is-disabled),
      &:not(.is-disabled):hover {
        border-left-color: $--radio-checked-border-color;
      }
    }
  }
}
</style>