<!-- 最小线宽/线距 -->
<template>
  <BaseFormItem
    :unit="fields.unit"
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <img slot="other" :src="require('@@/assets/img/pcb/lineweight_view.jpg')" />
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: Number,
      default: 6
    },
    // HDI(盲埋孔)
    blind: {
      type: Number,
      default: 0
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 外层铜厚
    copper: {
      type: Number,
      default: 1
    },
    // 内层铜厚
    insidecopper: {
      type: Number,
      default: 1
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFR4Fields('lineweight')
    },
    options() {
      const { val, fields, blayer, copper, insidecopper } = this
      const options = fields.options
      
      let disabledOptions = []
      if (blayer == 1) {
        disabledOptions.push(3.5, 4)
      }
      if (blayer == 2 && copper < 3) {
        disabledOptions.push(3.5)
      }
      if (copper == 2 || insidecopper == 2) {
        disabledOptions.push(3.5, 4, 5)
      }
      if ((copper == 3 || insidecopper == 3) && blayer >= 2 && blayer < 8) {
        disabledOptions.push(3.5, 4, 5, 6)
      }
      if ((copper == 4 || insidecopper == 4) && blayer >= 2 && blayer < 8) {
        disabledOptions.push(3.5, 4, 5, 6, 8)
      }
      
      // 如果当前项被禁用，选中第一个可选的选项
      if (disabledOptions.includes(val)) {
        this.changeVal(options.find(item => !disabledOptions.includes(item.value)).value)
      }
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    copper(copper) {
      const val = this.val
      if ((copper > 2 && val <= 6) || (copper > 3 && val == 8)) {
        this.changeVal(10)
      }
    },
    insidecopper(insidecopper) {
      const val = this.val
      if ((insidecopper > 2 && val <= 6) || (insidecopper > 3 && val == 8)) {
        this.changeVal(10)
      }
    },
    blind(blind) {
      if (blind > 0 && this.val != 3.5) { 
        this.changeVal(3.5)
      }
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    },
    // 修改最小线宽/线距
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>