<!-- 内层铜厚 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    // HDI(盲埋孔)
    blind: {
      type: Number,
      default: 0
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 外层铜厚
    copper: {
      type: Number,
      default: 1
    },
    // 板子厚度
    bheight: {
      type: [Number, String],
      default: 1.6
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initPcbParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('insidecopper')
    },
    options() {
      const { val, fields, blayer, blind, bheight } = this
      const options = fields.options
      let disabledOptions = []
      // 盲埋孔 == 无 && 板子层数 >= 12
      // 板子层数 == 8 && 板子厚度 == 1
      // 板子层数 == 10 && 板子厚度 == 1.2
      if ((blind <= 0 && blayer >= 12) || (blayer == 8 && bheight == 1) || (blayer == 10 && bheight == 1.2)) {
        disabledOptions.push(2)
      }
      // 盲埋孔 == 有
      if (blind > 0) {
        disabledOptions.push(2, 3, 4)
      }
      // 板子层数 >= 8 
      if (blayer >= 8) {
        disabledOptions.push(3, 4)
      }
      // 如果当前项被禁用，选中第一个可选的选项
      if (disabledOptions.includes(val)) {
        this.changeVal(options.find(item => !disabledOptions.includes(item.value)).value)
      }
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
      this.isShowError(false)
    },
    changeVal(val) {
      if (this.initPcbParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
</style>