<!-- 阶梯价格表 -->
<template>
  <transition name="el-zoom-in-top">
    <div>
      <p v-if="tips" class="ladder-price__tips">
        <i></i>
        {{ tips }}
      </p>
      <div class="ladder-price">
        <el-table 
          border 
          v-loading="loading"
          class="ladder-price__table"
          :data="list" 
          :cell-class-name="cellClassName"
        >
          <template v-for="(item, index) in tableHead">
            <el-table-column 
              v-if="index === 0"
              align="center" 
              label="板厚/价格" 
              width="100"
              class-name="ladder-price__table__firstColumn"
              label-class-name="ladder-price__table__firstCell"
            >
              <template slot="header" slot-scope="scope">
                <div class="ladder-price__table__bias">
                    <div class="top">面积</div>
                    <div class="bottom">板厚</div>
                  </div>
              </template>
              <div slot-scope="{ row }">
                {{ (row[index] || {}).showTitle }}
              </div>
            </el-table-column>
            <el-table-column 
              v-else
              align="center"
              :label="item.showTitle"
            >
              <div slot-scope="{ row }">
                {{ (row[index] || {}).showTitle }}
              </div>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
  </transition>
</template>

<script>
import { debounce } from '@@/utils/utils'
import { getPriceList } from '@@/api/pcb'

export default {
  props: {
    // 获取阶梯价参数
    ladderPriceData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      tableHead: [],
      list: [],
      tips: '',
      debounceGetPirce: debounce(this.getList, 300), // 接口防抖
    }
  },
  watch: {
    ladderPriceData(newVal, oldVal) {
      const hasChange = Object.keys(newVal).some(key => newVal[key] != oldVal[key])
      if (hasChange) {
        this.debounceGetPirce()
      }
    }
  },
  methods: {
    getList() {
      this.loading = true
      getPriceList(this.ladderPriceData)
        .then(res => {
          if (!res) return
          const data = res.data || {}
          const { tableHead, showList, tips } = data
          this.tableHead = tableHead || []
          this.list = showList || []
          this.tips = (tips || [])[0]
          this.$emit('tipsChange', this.tips)
          this.$emit('change', this.list)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 当前/最惠class
    cellClassName({ row, column, rowIndex, columnIndex }) {
      const { now, max } = row[columnIndex] || {}
      // 当前标识class
      if (now) return 'ladder-price__table__icon now'
      // 最惠标识class
      if (max) return 'ladder-price__table__icon max'
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.ladder-price__tips {
  margin-bottom: 8px;
  padding-left: 120px;
  font-size: 12px;
  >i {
    margin-right: 4px;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: bottom;
    background: url(~@@/assets/img/icons/icon_minus.png);
  }
}
.ladder-price {
  margin: 0 20px 18px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #EBEEF5;
  .ladder-price__table {
    font-size: 13px;
    ::v-deep {
      td, th {
        padding: 4px 0;
        font-weight: 400;
        color: #666666;
      }
      th,
      .ladder-price__table__firstColumn {
        background-color: #F5F7FA;
      }
      .ladder-price__table__firstCell.is-leaf {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 1px;
          left: 0;
          display: block;
          width: 1px;
          height: 110px;
          background-color: #EBEEF5;
          transform: rotate(-73deg);
          transform-origin: top;
        }
      }
      .ladder-price__table__icon {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 26px;
          height: 16px;
        }
        &.now::after {
          background: url(~@@/assets/img/icons/icon_ladder_price_now.png);
        }
        &.max::after {
          background: url(~@@/assets/img/icons/icon_ladder_price_max.png);
        }
        &.max {
          color: #F65001;
        }
      }
    }
    
    .ladder-price__table__bias {
      line-height: 1;
      font-size: 12px;
      .top {
        text-align: right;
        box-sizing: border-box;
      }
      .bottom {
        text-align: left;
        box-sizing: border-box;
      }
    }
  }
}
</style>