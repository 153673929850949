<!-- 工艺边框 -->
<template>
  <BaseFormItem
    class="sidewidth"
    :unit="fields.unit"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseInput 
      v-model="val.sidewidth" 
      width="130px" 
      class="sidewidth__input"
      :disabled="disabledInput || disabled"
      @change="handleSidewidthChange"
    >
      <BaseSelect 
        slot="prepend"
        v-model="val.sidedirection" 
        width="60px"
        placeholder="请选择"
        :disabled="disabled"
        @change="handleSidedirectionChange"
      >
        <el-option
          v-for="item in fields.options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </BaseSelect>
    </BaseInput>
    <span slot="other" class="sidewidth__tips">
      如需在华秋贴片，建议设置不小于5mm的工艺边框; 
      <span>无工艺边可能会增加额外的冶具费用</span>
    </span>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'

export default {
  props: {
    sidedirection: {
      type: String,
      default: ''
    },
    sidewidth: {
      type: [Number, String],
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: {
        sidedirection: this.sidedirection,
        sidewidth: this.sidewidth
      },
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('sidewidth')
    },
    disabledInput() {
      return this.val.sidedirection === '无'
    }
  },
  watch: {
    sidedirection(sidedirection) {
      this.val.sidedirection = sidedirection
    },
    sidewidth(sidewidth) {
      this.val.sidewidth = sidewidth
    }
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
      this.isShowError(false)
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
    handleSidedirectionChange(val) {
      if (val == '无') {
        this.val.sidewidth = 0
        this.handleEmit('sidewidth')
      } else {
        // 首次选工艺边方向后，工艺边框初始值改为 5
        if (this.val.sidewidth == 0) {
          this.val.sidewidth = 5
          this.handleEmit('sidewidth')
        }
      }
      this.handleEmit('sidedirection')
    },
    handleSidewidthChange(val) {
      val = Number(val)
      if (this.val.sidedirection != '无') {
        if (val < 3) {
          this.$message.warning('工艺边最少3mm')
          val = 3
        }
        if (val > 999) {
          this.$message.warning('工艺边最宽999mm')
          val = 5
        }
        if (val * 10 % 5 != 0) {
          this.$message.warning('工艺边框仅支持0.5的倍数，比如3.5, 4 , 4.5')
          val = Math.round(val * 2) / 2
        }
        this.val.sidewidth = val
      }
      this.handleEmit('sidewidth')
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.sidewidth ::v-deep {
  .sidewidth__input .el-input-group__prepend {
    background-color: #fff;
    cursor: pointer;
  }
  .el-input.is-disabled {
    .el-input__inner,
    .el-input__icon {
      cursor: pointer;
    }
    .el-input__inner[disabled] {
      cursor: not-allowed;
    }
  }
  .sidewidth__tips {
    font-size: 12px;
    color: $--color-warning;
    >span {
      color: $--color-text-placeholder;
    }
  }
}
</style>