<template>
  <el-dialog 
    append-to-body
    width="880px" 
    title="PCBA全额赔付确认" 
    top="100px" 
    custom-class="insurance-type-dialog"
    :close-on-click-modal="false" 
    :lock-scroll="false"
    :visible.sync="isShow" 
    @close="close"
  >
    <div class="insurance-type-dialog__content">
      <p class="insurance-type-dialog__tips">选择【PCBA全额赔付】方式，我司将收取质量保障服务费，费用以PCBA单片成本价和PCB订单单片总数量进行计算（若为拼版出货，会折算为单片数量）</p>
      
      <div class="insurance-type-dialog__input tips-report">
        <span>
          PCBA单片成本价
          <BaseTipPopover :content="`
            1、包含pcb板费、贴片费、元器件费\n
            2、请录入实际采购成本价，如超出则按实际PCBA采购成本价进行赔付
          `" />
        </span>

        <div>
          <BaseInput 
            v-model="insurance"
            width="480px"
            placeholder="单片成本价单双面不可超过1000元，多层板不可超过2000元" 
            @input="debounceComputePrice"
          >
            <template slot="append">元/pcs</template>
          </BaseInput>

          <p v-if="errorTips">{{ errorTips }}</p>
        </div>
      </div>

      <div v-loading="feeLoading" class="insurance-type-dialog__cost">
        <p>
          PCB品质服务费：
          <i>￥</i>
          <span>{{ insurancefee || 20 }}</span>
        </p>
        <p v-if="pcbParams.units == 3">*当前订单为按文件拼版，无法获知具体单片数量，价格以审核为准</p>
      </div>

      <div class="insurance-type-dialog__agreement">
        <div v-for="(item, index) in agreement" :key="index">
          <p>{{ item.title }}</p>
          <ul>
            <li v-for="(i, iIndex) in item.list" :key="iIndex">
              <template v-if="typeof i === 'object'">
                <b>{{ i.text }}</b>
              </template>
              <template v-else>
                {{ i }}
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div slot="footer" class="insurance-type-dialog__footer">
      <p>
        <el-checkbox v-model="isAgree">我已知悉并同意以上内容</el-checkbox>
      </p>
      
      <el-button @click="close">{{ isEdit ? '取消修改' : '取 消'}}</el-button>
      <el-button type="primary" :disabled="disabled" @click="confirm">{{ isEdit ? '确认修改' : '确 认' }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { debounce } from '@@/utils/utils'
import { formatPcbParams } from '@@/utils/pcbParamsFormat'
import { getPcbPrice } from '@@/api/pcb'

export default {
  props: {
    pcbParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isShow: false,
      isEdit: false,
      feeLoading: false, // 获取费用loading
      insurance: '', // 单片成本
      insurancefee: '', // PCB品质服务费
      isAgree: false,
      debounceComputePrice: debounce(this.getPcbPrice, 500), // 对输入框进行防抖
      agreement: [
        {
          title: '费用计算',
          list: [
            '单双层板=PCBA单片成本价*0.005服务费*PCB单片总数量：例如：PCBA成本价100元/片，PCB 100片，服务费=100*0.005*100=50元。',
            '多层板=PCBA单片成本价*0.01服务费*PCB单片总数量：例如：PCBA成本价100元/片，PCB 100片，服务费=100*0.01*100=100元。',
            '如上述品质服务费不足20元，则按20元收取。'
          ]
        },
        {
          title: '赔偿金额',
          list: [
            '按实际不良PCB单片数量进行赔付，最多不超过订单单片总数量。',
            '扣除可二次利用的电子元器件的成本后，按每片PCBA本身的实际成本进行赔付，其中，每片单双层板的最高赔付不超过1000元，每片多层板的最高赔付不超过2000元。',
            '对于单笔订单，单双面板的最高赔付不超过25万元，多层板的最高赔付不超过50万元。'
          ]
        },
        {
          title: '赔付条件',
          list: [
            { text: '仅限于我司在生产过程造成的线路开路、短路、过孔似断非断、过孔不通。如因您提供的工程资料错误引起的开路、短路，以及外形、阻焊、字符、公差等各种工程资料原因导致的品质问题，均不在本赔付范围内。', style: 'bold' },
            '如发现特定品质问题，不能擅自进行维修，须第一时间联系我司；如不认可华秋的鉴定结果，可提交给有资质第三方鉴定公司，第三方鉴定公司暂指定为经中国认监委批准、国内外多个组织认可和授权、专业从事第三方认证的“广州赛宝认证中心”进行认证；若因自行维修导致没法取证，不在本赔付范围内。',
            '仅限正常环境下出现的问题，特殊环境下出的问题不在此赔偿范围内，包括但不限于如：强酸、强碱、高温、极寒、撞击、震动、潮湿、湿热等。',
            '如对线路板的质量有异议，请于PCB收货之日起在合同约定的期限内提出，逾期则视为线路板无问题，我司可不予赔偿。',
            '如确认为品质问题，贵司须寄回PCBA，我司收到后一周内完成赔付，PCBA所有权归华秋所有。'
          ]
        },
        {
          title: '其它说明',
          list: [
            '优惠券、优惠活动暂不支持品质服务费的抵扣。'
          ]
        }
      ]
    }
  },
  computed: {
    // 输入错误提示
    errorTips() {
      const { insurance, pcbParams: { blayer } } = this
      const _val = Number(insurance)
      let tips = ''

      // 板子层数=1或2，需大于0且小于等于1000
      if ((blayer == 1 || blayer == 2) && insurance !== '' && !(0 < _val && _val <= 1000)) {
        tips = '单双面板的PCBA成本价不可超过1000元'
      }
      // 板子层数>2，需大于0且小于等于2000
      if (blayer > 2 && insurance !== '' && !(0 < _val && _val <= 2000)) {
        tips = '多层板的PCBA成本价不可超过2000元'
      }
      return tips
    },

    // 禁用确认按钮
    disabled() {
      const { insurance, isAgree, errorTips } = this
      return !insurance || !isAgree || !!errorTips
    }
  },
  methods: {
    // 打开弹框
    open() {
      const insurance = this.pcbParams.insurance

      if (insurance) {
        this.insurance = insurance
        this.getPcbPrice()
        this.isEdit = true
      } else {
        this.insurance = ''
        this.insurancefee = ''
        this.isEdit = false
      }
      this.isShow = true
    },
    //关闭弹框
    close() {
      this.isShow = false
    },
    // 确认
    confirm() {
      this.$emit('confirm', this.insurance)
      this.isShow = false
    },
    // PCB 计价
    getPcbPrice() {
      const { pcbParams, insurance } = this
      const params = formatPcbParams(pcbParams, {insurance: Number.parseFloat(insurance).toFixed(2), insurance_type: 1})
      this.feeLoading = true
      getPcbPrice(params)
        .then(res => {
          if (!res) return {}
          const { retCode, result } = res
          if (retCode === 0) {
            this.insurancefee = result.insurance_amount
          }
        })
        .finally(() => {
          this.feeLoading = false
        })
    },
  }
}
</script>

<style lang="scss">
.insurance-type-dialog {
  .el-dialog__header .el-dialog__title {
    font-size: 22px;
    font-weight: 700;
    color: $--color-text-primary;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog__footer {
    padding-bottom: 30px;
    text-align: center;
  }

  .insurance-type-dialog__tips {
    font-size: 12px;
    color: $--color-warning;
  }

  .insurance-type-dialog__input {
    display: flex;
    padding: 30px 0;
    border-bottom: 1px dashed #dcdfe6;
    >span {
      margin-right: 10px;
      line-height: 32px;
      font-size: 14px;
      color: $--color-text-primary;
    }
    >div {
      .el-input {
        width: 440px;
      }
      >p {
        margin-top: 10px;
        font-size: 12px;
        color: $--color-primary;
      }
    }
  }

  .insurance-type-dialog__cost {
    text-align: right;
    >p {
      &:first-child {
        padding-top: 20px;
        font-size: 14px;
        color: $--color-text-primary;

        >i {
          color: $--color-primary;
        }

        >span {
          font-size: 20px;
          font-weight: 700;
          color: $--color-primary;
        }
      }

      &:last-child {
        margin-top: 6px;
        font-size: 12px;
        color: $--color-text-placeholder;
      }
    }
  }

  .insurance-type-dialog__agreement {
    margin-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    height: 280px;
    font-size: 14;
    color: $--color-text-primary;
    background-color: #F5F7FA;
    overflow-y: auto;
    // 设置滚动边界
    overscroll-behavior-y: contain;
    p {
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: 700;
    }
    li {
      margin-left: 20px;
      // margin-bottom: 10px;
      list-style: disc;
      font-size: 12px;
      >b {
        font-weight: 700;
      }
    }
  }

  .insurance-type-dialog__footer {
    .el-checkbox {
      margin-bottom: 20px;
      font-size: 14px;
      &.is-checked {
        .el-checkbox__inner {
          background-color: $--color-primary;
          border-color: $--color-primary;
        }
        .el-checkbox__label {
          color: $--color-primary;
        }
      }
    }

    .el-button {
      padding: 12px 40px;

      &.el-button--primary {
        background-color: $--color-primary;
        border-color: $--color-primary;

        &:hover {
          background-color: $--color-primary;
          border-color: $--color-primary;
        }

        &.is-disabled {
          color: #666666;
          background-color: #DCDFE6;
          border-color: #DCDFE6;
        }
      }
    }
  }
}
</style>