<template>
  <div class="pressing-view">
    <BaseTextButton @click="open">查看全部叠层</BaseTextButton>
    <el-dialog
      width="780px"
      top="100px"
      title="华秋叠层结构"
      custom-class="pressing-view-dialog"
      append-to-body
      :visible="isShow"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="isShow = false"
    >
      <div>
        <!-- 板子信息 -->
        <ul class="pressing-view-dialog__params">
          <li v-for="(item, key) in labels">
            <span>{{item.label}}：</span>
            <span>{{item.formatter ? item.formatter(params[key]) : params[key]}}</span>
          </li>
        </ul>
        <!-- 叠层结构选项 -->
        <ul class="pressing-view-dialog__options">
          <li v-for="(item, index) in list" :key="index">
            <span 
              :class="{ 'active' : currentPressingNo === item.press_no_and_pp}"
              @click="currentPressingNo = item.press_no_and_pp"
            >
              华秋{{item.press_name}}
            </span>
          </li>
        </ul>
        <!-- 当前选项的压合结构 -->
        <el-table 
          v-if="stack"
          border
          size="small"
          class="pressing-view-dialog__table" 
          :show-header="false"
          :data="stack"
        >
          <el-table-column prop="layer_name" width="60"></el-table-column>
          <el-table-column width="200">
            <template slot-scope="{ row }">
              <div :class="`pressing-view-dialog__table__${row.material_type}`">
                <div v-if="row.material_type == 2">{{row.material_name}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="material"></el-table-column>
          <el-table-column prop="material_properties"></el-table-column>
          <el-table-column 
            prop="top_conductor_thick" 
            :formatter="row => `${row.total_thick}mm`"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      defalut: () => ({})
    },
    list: {
      type: Array,
      default: () => []
    },
    pressingNo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShow: false,
      currentPressingNo: '',
      labels: {
        blayer: { label: '板子层数' }, // 层数
        bheight: { label: '板子厚度', formatter: val => `${val}mm` }, // 板厚
        copper: { label: '外层铜厚', formatter: val => `${val}oz` }, // 外层铜厚
        insidecopper: { label: '内层铜厚', formatter: val => `${val}oz` }, // 内层铜厚
        hdi: { label: 'HDI(盲埋孔)', formatter: val => ({ 0: '无', 1: '有' }[val]) }, // HDI(盲埋孔) 1:有，0:无
      }
    }
  },
  computed: {
    stack() {
      const { currentPressingNo, list } = this
      if (currentPressingNo) {
        const item = list.find(i => currentPressingNo === i.press_no_and_pp)
        return item ? item.hqimpe_detail.stack : null
      }
      return null
    }
  },
  methods: {
    open() {
      const { list, pressingNo } = this
      this.currentPressingNo = pressingNo
      // 当没有选中的值时，默认展示第一个
      if (!pressingNo || !list.find(i => pressingNo === i.press_no_and_pp)) {
        this.currentPressingNo = list[0].press_no_and_pp
      }
      this.isShow = true
    }
  }
}
</script>

<style lang="scss">
.pressing-view {
  display: inline-block;
  margin-right: 10px;
}
.pressing-view-dialog {
  .el-dialog__body {
    padding-top: 10px;
  }

  .pressing-view-dialog__params {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    font-size: 14px;
    color: $--color-text-primary;
    border-bottom: 1px dashed $--border-color-base;
  }

  .pressing-view-dialog__options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    >li {
      margin-top: 20px;
      padding-left: 16px;
      width: 30%;
      >span {
        display: inline-block;
        padding-bottom: 6px;
        cursor: pointer;
        border-bottom: 1px solid transparent;
        &:hover,
        &.active {
          color: #059A58;
          border-bottom-color: #059A58;
        }
      }
    }
  }

  .pressing-view-dialog__table {
    &__0 {
      height: 6px;
      background-color: #6F4C1B;
    }

    &__1 {
      height: 6px;
      background-color: #F4BB69;
    }

    &__2 {
      padding: 6px 0;
      height: 32px;
      background-color: #F4BB69;

      >div {
        line-height: 20px;
        height: 20px;
        text-align: center;
        color: #FFFFFF;
        background-color: #6F4C1B;
      }
    }

    &__3 {
      height: 32px;
      background-color: #6F4C1B;
    }
  }
}
</style>