<template>
  <el-table 
    border
    class="pressing-stack pressing-table" 
    :data="list"
  >
    <!-- 使用层 -->
    <el-table-column prop="layer_name" label="使用层"></el-table-column>
    <!-- 图形 -->
    <el-table-column width="500" label="图形">
      <template slot-scope="{ row }">
        <div :class="`pressing-stack__${row.material_type}`">
          <div v-if="row.material_type == 2">
            {{ row.material_name }}
          </div>
        </div>
      </template>
    </el-table-column>
    <!-- 材料 -->
    <el-table-column prop="material" label="材料"></el-table-column>
    <!-- 规格 -->
    <el-table-column prop="material_properties" label="规格"></el-table-column>
    <!-- 压合厚度 -->
    <el-table-column 
      prop="top_conductor_thick" 
      label="压合厚度"
      :formatter="row => `${row.total_thick}mm`"
    ></el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: []
    }
  },
}
</script>

<style lang="scss" scoped>
.pressing-stack {
  &__0 {
    height: 6px;
    background-color: #6F4C1B;
  }
  &__1 {
    height: 6px;
    background-color: #F4BB69;
  }
  &__2 {
    padding: 6px 0;
    height: 32px;
    background-color: #F4BB69;
    >div {
      line-height: 20px;
      height: 20px;
      text-align: center;
      color: #FFFFFF;
      background-color: #6F4C1B;
    }
  }
  &__3 {
    height: 32px;
    background-color: #6F4C1B;
  }
}
</style>