<!-- 订单支付方式 -->
<template>
  <BaseFormItem
    class="deduct_type"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val.deduct_type">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
        @click.native.prevent="handleRadioClick(item.value)"
      >
        {{ item.label }}
      </el-radio-button>
    </BaseRadioGroup>
    <div v-if="val.deduct_type == 1" class="deduct_type__limit">
      <i class="deduct_type__limit__linker"></i>
      <div class="deduct_type__limit__content" :class="{ 'is-warning': isWarning }">
        <div>
          <p class="deduct_type__limit__title">差额设置</p>
          <p class="deduct_type__limit__tips">
            如<span>审核后总价</span>与<span>预估总价</span>正负相差超过
            <template v-if="showInput">设定值</template>
            <i v-else>{{ val.deduct_limit }}</i>
            ，则<i>不允许</i>自动扣款
          </p>
          <p v-if="showInput" class="deduct_type__limit__input">
            <BaseInput 
              v-model="val.deduct_limit" 
              width="112px" 
              placeholder="扣款差额"
            ></BaseInput>
            <BaseButton round @click="handleConfirm">确定</BaseButton>
            <span v-if="isWarning">请点击确认按钮，确认扣款差额</span>
          </p>
          <p v-if="isError" class="deduct_type__limit__error">请输入正确的差额范围：<span>大于等于0</span></p>
        </div>
        <BaseTextButton v-if="!showInput" @click="showInput = true">修改</BaseTextButton>
      </div>
      <!-- 账号余额 -->
      <div class="deduct_type__limit__balance" :class="{ 'has-tips': isShowBalanceTips }">
        <p>账户余额：￥{{ balance }}</p>
        <div v-if="isShowBalanceTips">
          <i class="el-icon-warning" />
          余额不足以支付订单，为避免自动扣款失败，请提前充值
        </div>
      </div>
    </div>
  </BaseFormItem>
</template>

<script>
import { getFR4Fields } from '@@/utils/getFields'
import { getBalance } from '@@/api/pcb'

export default {
  props: {
    // 订单支付方式
    deduct_type: {
      type: [Number, String],
      default: ''
    },
    // 自动扣款差额范围
    deduct_limit: {
      type: [Number, String],
      default: ''
    },
    // 订单总计价格
    priceTotal: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showInput: !this.deduct_limit && this.deduct_limit !== 0,
      isWarning: false,
      isError: false,
      val: {
        deduct_type: this.deduct_type,
        deduct_limit: this.deduct_limit
      },
      balance: 0, // 账户余额
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFR4Fields('deduct_type')
    },
    // 是否显示未填写样式
    fillTips() {
      const deduct_type = this.val.deduct_type
      return !deduct_type ? '请选择' : ''
    },
    isShowBalanceTips() {
      return this.balance < this.priceTotal
    }
  },
  watch: {
    deduct_type(newVal) {
      this.val.deduct_type = newVal
    },
    deduct_limit(newVal) {
      if (newVal || newVal === 0) {
        this.showInput = false
      }
      this.val.deduct_limit = newVal
    },
  },
  created() {
    this.getBalance()
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
      this.handleEmitChange()
      this.isShowError(false)
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
    handleRadioClick(deduct_type) {
      this.val.deduct_type = deduct_type
      this.handleEmit('deduct_type')
      if (deduct_type == 1) {
        this.showInput = !this.val.deduct_limit
      } else {
        this.val.deduct_limit = ''
        this.handleEmit('deduct_limit')
      }
    },
    handleConfirm() {
      const deduct_limit = this.val.deduct_limit
      if (!deduct_limit || isNaN(Number(deduct_limit)) || Number(deduct_limit) < 0) return this.isError = true
      this.val.deduct_limit = Number.parseFloat(deduct_limit).toFixed(2)
      this.handleEmit('deduct_limit')
      this.showInput = false
      this.isError = false
      this.isWarning = false
    },
    // 获取账户余额
    getBalance() {
      getBalance()
        .then(res => {
          if (!res) return
          const { retCode, result } = res
          if (retCode === 0) {
            this.balance = result.balance
          }
        })
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@@/styles/mixin.scss';
.deduct_type {
  .deduct_type__changeBtn {
    margin-left: 2px;
    padding: 0;
    border: 0;
    border-left: $--border-base;
    color: $--form-color-base;
  }

  .deduct_type__limit {
    position: relative;
    margin-top: 6px;
    width: 540px;
    .deduct_type__limit__linker {
      @include moduleLinkerMixin(15px, vertical, dark);
      position: absolute;
      top: -10px;
      left: 6px;
    }
    .deduct_type__limit__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 15px 11px;
      border-radius: 5px;
      border: 1px dashed $--background-hover;
      background-color: $--background-hover;
      &.is-warning {
        border-color: $--color-warning;
        background-color: $--tips-background-color;
      }
      .deduct_type__limit__title {
        line-height: 18px;
        font-weight: 700;
      }
      .deduct_type__limit__tips {
        >span {
          margin: 0 2px;
          color: $--color-warning;
        }
        >i {
          margin: 0 2px;
          font-weight: 700;
          color: $--color-danger;
        }
      }
      .deduct_type__limit__input ::v-deep {
        .base-input {
          position: relative;
          margin-right: 6px;
          &::before {
            content: '￥';
            position: absolute;
            left: 4px;
          }
          .el-input__inner {
            padding-left: 20px;
          }
        }
        >span {
          margin-left: 10px;
          font-size: 12px;
          color: $--color-danger;
        }
      }
      .deduct_type__limit__error {
        font-size: 12px;
        line-height: 18px;
        color: $--color-danger;
        >span {
          color: $--color-text-placeholder;
        }
      }
      .base-text-button {
        background-color: #fff;
        &:hover {
          border-color: $--form-color-base;
        }
      }
    }
    .deduct_type__limit__balance {
      display: flex;
      align-items: center;
      margin-top: 2px;
      font-size: 12px;
      color: $--color-text-secondary;
      &.has-tips {
        >p {
          flex: none;
        }
      }
      >p {
        flex: 1;
        padding: 0 15px;
        line-height: 28px;
        border-radius: 5px;
        background-color: $--background-hover;
      }
      >div {
        flex: 1;
        margin-left: 1px;
        padding: 0 10px;
        line-height: 28px;
        border-radius: 5px;
        background-color: $--tips-background-color;
        >i {
          color: $--tips-color;
        }
      }
    }
  }
}
</style>